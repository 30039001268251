/**
 * SearchService
 * Handles the searching operations
 * */
import { sget, spost } from "./io"
import { isCandidates } from "../utils/utils"
import { isProfileMode } from "utils/productUtils"
const url = "/search"

/**
 * Search for candidates
 * @param page
 * @param sortBy
 * @param orderBy
 * @param resource
 * @returns {AxiosPromise}
 */
export const searchCandidates = (
  organization,
  page = 0,
  sortBy,
  orderBy,
  data,
  resultsPerPage = 20,
  dontUseFetchCandidatesLambda
) => {
  const postfix = getEndpointPostfix(dontUseFetchCandidatesLambda)

  return spost({
    url: `${url}/${postfix}/${organization && organization.id}`,
    params: { page, sortBy, orderBy, resultsPerPage },
    data,
  })
}

export const getCandidates = (organization, searchTerm, options) => {
  const params = {
    searchTerm,
    ...options,
  }
  const postfix = isProfileMode() ? "profiles" : `candidates/${organization.id}`
  return sget({
    url: `${url}/${postfix}`,
    params,
  })
}

const getEndpointPostfix = dontUseFetchCandidatesLambda => {
  if (isCandidates()) {
    return "candidatev"
  }

  if (dontUseFetchCandidatesLambda) {
    return "candidate"
  }

  return "fastCandidate"
}

/**
 * Wrapper layout for the app
 * Handles the display of the app messages returned from async operations through the Process component
 * Checks if the user exists or needs to be reloaded
 */

import React from "react"
import PropTypes from "prop-types"
import AuthService from "services/auth"
import Process from "components/Process"
import ReactGA from "utils/GA"
import { isGTMTracker } from "utils/clientUtils"
import TagManager from "react-gtm-module"

export class WrapperLayout extends React.Component {
  componentDidMount() {
    const { user, getUser, clearUser, location, organization } = this.props
    const isLoggedIn = AuthService.loggedIn()
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + window.location.search,
      title: location.pathname,
    })
    if (isLoggedIn) {
      getUser()
    } else if (!user.id && !isLoggedIn) {
      clearUser()
      AuthService.clearAuthInfo()
    }
    const tracker = isGTMTracker(organization)
    if (tracker?.gtmId) {
      TagManager.initialize(tracker)
    }
  }

  componentWillUpdate(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      ReactGA.send({
        hitType: "pageview",
        page: nextProps.location.pathname + window.location.search,
        title: nextProps.location.pathname,
      })
    }
    if (nextProps.organization && !this.props.organization) {
      const tracker = isGTMTracker(nextProps.organization)
      if (tracker?.gtmId) {
        TagManager.initialize(tracker)
      }
    }
  }

  render() {
    return (
      <div>
        {this.props.children}
        {<Process process={this.props.process} />}
      </div>
    )
  }
}

WrapperLayout.propTypes = {
  children: PropTypes.node,
}

export default WrapperLayout

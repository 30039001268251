/**
 * Menu reducer
 * Saves references to menus and allows closing / opening them remotely
 */

import { createAction, handleActions } from "redux-actions"

// ------------------------------------
// Constants
// ------------------------------------
export const MENU_OPEN_PROFILE = "MENU_OPEN_PROFILE"
export const MENU_CLOSE_PROFILE = "MENU_CLOSE_PROFILE"
export const MENU_OPEN_ACCOUNT = "MENU_OPEN_ACCOUNT"
export const MENU_CLOSE_ACCOUNT = "MENU_CLOSE_ACCOUNT"
export const MENU_OPEN_USER = "MENU_OPEN_USER"
export const MENU_CLOSE_USER = "MENU_CLOSE_USER"
export const MENU_CLOSE_ALL = "MENU_CLOSE_ALL"

// ------------------------------------
// Actions
// ------------------------------------
const openProfileMenu = createAction(MENU_OPEN_PROFILE)
const closeProfileMenu = createAction(MENU_CLOSE_PROFILE)
const openAccountMenu = createAction(MENU_OPEN_ACCOUNT)
const closeAccountMenu = createAction(MENU_CLOSE_ACCOUNT)
const openUserMenu = createAction(MENU_OPEN_USER)
const closeUserMenu = createAction(MENU_CLOSE_USER)
const closeAllMenus = createAction(MENU_CLOSE_ALL)

export const actions = {
  openProfileMenu,
  closeProfileMenu,
  openAccountMenu,
  closeAccountMenu,
  openUserMenu,
  closeUserMenu,
  closeAllMenus,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isProfileMenuOpen: false,
  isAccountMenuOpen: false,
  isUserMenuOpen: false,
}

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return { ...initialState }
    },
    MENU_OPEN_PROFILE: (state, { payload }) => {
      return {
        ...state,
        isProfileMenuOpen: true,
        isAccountMenuOpen: false,
        isUserMenuOpen: false,
      }
    },
    MENU_CLOSE_PROFILE: (state, { payload }) => {
      return {
        ...state,
        isProfileMenuOpen: false,
        isAccountMenuOpen: false,
        isUserMenuOpen: false,
      }
    },
    MENU_OPEN_ACCOUNT: (state, { payload }) => {
      return {
        ...state,
        isAccountMenuOpen: true,
        isProfileMenuOpen: false,
        isUserMenuOpen: false,
      }
    },
    MENU_CLOSE_ACCOUNT: (state, { payload }) => {
      return {
        ...state,
        isAccountMenuOpen: false,
        isProfileMenuOpen: false,
        isUserMenuOpen: false,
      }
    },
    MENU_OPEN_USER: (state, { payload }) => {
      return {
        ...state,
        isUserMenuOpen: true,
        isProfileMenuOpen: false,
        isAccountMenuOpen: false,
      }
    },
    MENU_CLOSE_USER: (state, { payload }) => {
      return {
        ...state,
        isUserMenuOpen: false,
        isProfileMenuOpen: false,
        isAccountMenuOpen: false,
      }
    },
    MENU_CLOSE_ALL: (state, { payload }) => {
      return {
        ...state,
        isUserMenuOpen: false,
        isAccountMenuOpen: false,
        isProfileMenuOpen: false,
      }
    },
  },
  initialState
)

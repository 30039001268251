/**
 * User reducer
 * Handles user actions
 */

import { createAction, handleActions } from "redux-actions"
import UserService from "services/user"
import { actions as processActions } from "modules/process"
import { actions as accountActions } from "modules/account"
import AuthService from "services/auth"
import { actions as dataActions } from "modules/data"

// ------------------------------------
// Constants
// ------------------------------------
export const USER_LOADING = "USER_LOADING"
export const USER_SUCCESS = "USER_SUCCESS"
export const USER_ERROR = "USER_ERROR"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_CLEAR = "USER_CLEAR"
export const PREVIEW_CANDIDATE = "PREVIEW_CANDIDATE"

// ------------------------------------
// Actions
// ------------------------------------
const userLoading = createAction(USER_LOADING)
const userSuccess = createAction(USER_SUCCESS)
const userError = createAction(USER_ERROR)
const userUpdateSuccess = createAction(USER_UPDATE_SUCCESS)
const clearUser = createAction(USER_CLEAR)

// types
const TYPE_USER_LOAD = "user:load"
const TYPE_USER_DELETE = "user:delete"
const TYPE_USER_UPDATE = "user:update"
const TYPE_USER_AVATAR_UPLOAD = "user:avatar:upload"

/**
 * Load user
 * @returns {function(*)}
 */
const getUser = requestCookie => {
  return (dispatch, getState) => {
    dispatch(userLoading())
    processActions.processLoading({
      type: TYPE_USER_LOAD,
    })
    return UserService.getUser(requestCookie).then(
      response => {
        processActions.processSuccess({
          type: TYPE_USER_LOAD,
        })
        const payload = response.data
        if (response.data.organization) {
          dispatch(
            dataActions.setOrganization({ data: response.data.organization })
          )
        }
        return dispatch(userSuccess(payload))
      },
      error => {
        dispatch(userError())
        AuthService.clearAuthInfo()
        return dispatch(
          processActions.processFailure({
            type: TYPE_USER_LOAD,
            message: error,
          })
        )
      }
    )
  }
}

/**
 * Update user
 * @param updateObj
 * @returns {Function}
 */
const updateUser = updateObj => {
  return (dispatch, getState) => {
    const { user } = getState()
    const newUserObj = { ...user, ...updateObj }

    const processType = updateObj.hasOwnProperty("avatarBase64")
      ? TYPE_USER_AVATAR_UPLOAD
      : TYPE_USER_UPDATE

    dispatch(
      processActions.processLoading({
        type: processType,
      })
    )

    UserService.updateCurrentUser(newUserObj).then(
      response => {
        dispatch(
          processActions.processSuccess({
            type: processType,
          })
        )
        return dispatch(userUpdateSuccess(response.data))
      },
      error => {
        return dispatch(
          processActions.processFailure({
            type: processType,
            message: error,
          })
        )
      }
    )
  }
}

const deleteUser = () => {
  return (dispatch, getState) => {
    UserService.deleteUser().then(
      _ => {
        return dispatch(accountActions.logout("/signup"))
      },
      error => {
        return dispatch(
          processActions.processFailure({
            type: TYPE_USER_DELETE,
            message: error,
          })
        )
      }
    )
  }
}

export const actions = {
  getUser,
  updateUser,
  userSuccess,
  clearUser,
  deleteUser,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  tempState: null,
  loading: true,
}

export default handleActions(
  {
    USER_LOADING: () => {
      return { ...initialState }
    },
    LOGOUT_SUCCESS: () => {
      return { ...initialState, loading: false }
    },
    USER_SUCCESS: (state, { payload }) => {
      return { ...state, ...payload, loading: false }
    },

    USER_ERROR: state => {
      return { ...state, loading: false }
    },

    USER_UPDATE_SUCCESS: (state, { payload }) => {
      return { ...payload, loading: false }
    },

    USER_CLEAR: () => {
      return { ...initialState, loading: false }
    },

    PREVIEW_CANDIDATE: (state, { payload }) => {
      return { ...state, ...payload }
    },

    USER_ORGANIZATION_SUCCESS: (state, { payload }) => {
      return { ...state, organization: payload }
    },
  },
  initialState
)

import React from "react"
import PropTypes from "prop-types"

export const AlertBody = ({ text, bodyComponent }) => {
  const bodyContent = text ? <span id="alert_text">{text}</span> : bodyComponent
  return <div className="alert__body">{bodyContent}</div>
}

AlertBody.propTypes = {
  title: PropTypes.string,
  bodyContent: PropTypes.element,
}

export default AlertBody

import PropTypes from "prop-types"
import styled from "styled-components"
import { fontSize, mobileFontSize, media } from "utils/utils"

export const Text = styled.span`

  ${props => (props.inherit ? "font-size: inherit;" : fontSize(props.variant))}
  
  ${props => props.underline && `border-bottom: 1px dotted #323c44;`}

  font-weight: ${props => props.theme.typography.weights[props.weight]};
  
  text-transform: ${props =>
    props.texttransform ? props.texttransform : "normal"};
  
  ${props => (props.inheritColor ? "color: inherit" : null)};
  
  ${props => (props.margin ? `margin: ${props.margin};` : null)}
  
  a {
    display:inline-lock;
    text-decoration: none;
    padding-bottom: 1px;
    color: ${props =>
      props.inherit
        ? "inherit"
        : `${props.theme.typography.colors[props.variant]}`};
    border-bottom: 1px dotted  ${props =>
      props.theme.typography.colors[props.variant]};
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  
  a:hover {
    color: ${props => props.theme.typography.invertedColors[props.variant]};
    border-bottom: 1px dotted  ${props =>
      props.theme.typography.invertedColors[props.variant]};
  }

  ${media.md`
    ${props => mobileFontSize(props.variant, "md")};
  `};
`

Text.defaultProps = {
  inherit: false,
  inheritColor: false,
  variant: "text",
  weight: "regular",
  margin: null,
}

Text.propTypes = {
  inherit: PropTypes.bool,
  inheritColor: PropTypes.bool,
  variant: PropTypes.string,
  weight: PropTypes.string,
  margin: PropTypes.string,
}

export default Text

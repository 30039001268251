/**
 * Display an svg icon from an svg sprite file
 * See a list of all available Icon types in assets/svgs
 * Example useage <Icon type="avatar" />
 */
import React from "react"
import PropTypes from "prop-types"
import { SvgWrapper, IconToolTip, ToolTipWrapper } from "./styles"

export class Icon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShown: false,
      timer: false,
    }
  }

  startTimer = () => {
    this.setState({ timer: true })
    setTimeout(() => {
      this.showTimer()
    }, 1000)
  }

  showTimer() {
    if (this.state.timer) {
      this.setState({ isShown: true })
    }
  }

  hideTooltip = () => {
    this.setState({ timer: false })
    this.setState({ isShown: false })
  }

  render() {
    const { id, variant, className, tooltip } = this.props
    const { isShown } = this.state

    return (
      <ToolTipWrapper
        onMouseEnter={this.startTimer}
        onMouseLeave={this.hideTooltip}
      >
        {tooltip != null && isShown && (
          <IconToolTip show={false}>{tooltip}</IconToolTip>
        )}
        <SvgWrapper id={id} className={className} value={variant}>
          <use xlinkHref={`/sprite.svg#${variant}`} />
        </SvgWrapper>
      </ToolTipWrapper>
    )
  }
}

Icon.defaultProps = {
  tooltip: null,
}

Icon.propTypes = {
  variant: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
}

export default Icon

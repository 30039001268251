import axios from "axios"
import AuthService from "services/auth"
import { getSubdomainFromUrl } from "utils/productUtils"
import { baseURL } from "utils/config"
import store from "store"
import { actions as accountActions } from "modules/account"
import UserSessionHelper from "../utils/userSession"

export const action = (options, { authRequired }) => {
  const authInfo = UserSessionHelper.load()
  if (
    !authRequired ||
    (authInfo && (authInfo.refresh_token || !AuthService.isSavedTokenExpired()))
  ) {
    return axios({
      ...options,
      params: {
        ...options.params,
        subdomain: getSubdomainFromUrl() || null,
      },
    })
  } else {
    AuthService.clearAuthInfo()
    setTimeout(() => {
      store.dispatch(
        accountActions.logout(
          null,
          window.location.pathname,
          window.location.search
        )
      )
    }, 100)
    return new Promise((_, reject) => reject())
  }
}

const unathenticated = (options, method) => {
  return action(
    {
      ...options,
      url: `${baseURL}${options.url}`,
      method,
      headers: AuthService.getDefaultHeaders(),
    },
    { authRequired: false }
  )
}

export const externalPost = (url, body, options) => {
  return axios.post(url, body, options)
}

export const get = options => unathenticated(options, "GET")

export const post = options => unathenticated(options, "POST")

export const put = options => unathenticated(options, "PUT")

export const authenticated = (options, method) => {
  const apiURL = baseURL + "/api/v1"
  return action(
    {
      ...options,
      url: `${apiURL}${options.url}`,
      method,
      headers: AuthService.getAuthorizationHeaders(),
    },
    { authRequired: true }
  )
}

export const sget = options => authenticated(options, "GET")

export const sput = options => authenticated(options, "PUT")

export const spost = options => authenticated(options, "POST")

export const sdelete = options => authenticated(options, "DELETE")

import React from "react"
import PropTypes from "prop-types"

export const AlertHeader = ({ title, headerComponent }) => {
  return <div className="alert__header">{title || headerComponent}</div>
}

AlertHeader.propTypes = {
  title: PropTypes.string,
  headerComponent: PropTypes.element,
}

export default AlertHeader

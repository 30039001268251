import React from "react"
import PropTypes from "prop-types"
import AlertHeader from "./Alert.Header"
import AlertBody from "./Alert.Body"
import "./Alert.css"
import { checkTextForEmail } from "utils/utils"

export class Alert extends React.Component {
  constructor(props) {
    super(props)
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.onShow = this.onShow.bind(this)
    this.onHide = this.onHide.bind(this)

    this.classTimer = null
    this.hideTimer = null

    this.state = {
      className: "alert",
      show: true,
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.classTimer)
    window.clearTimeout(this.hideTimer)
  }

  componentDidMount() {
    const { timeout, type } = this.props

    window.clearTimeout(this.classTimer)
    window.clearTimeout(this.hideTimer)

    this.classTimer = setTimeout(() => {
      this.setState({
        className: "alert alert--visible alert--" + type,
      })
    }, 10)

    this.hideTimer = setTimeout(() => {
      this.setState({
        className: "alert alert--hidden alert--" + type,
        show: false,
      })
    }, timeout)
  }

  /* componentWillUnmount() {
    const { timeout, type } = this.props;
    this.state = {
      className: "alert alert--hidden  alert--" + type
    };
  }*/

  hide() {
    this.onHide()
    this.setState({ show: false })
  }

  show() {
    this.onShow()
    this.setState({ show: true })
  }

  onShow() {}

  onHide() {}

  render() {
    const { className } = this.state
    const { title, text, headerComponent, bodyComponent } = this.props
    let modified = checkTextForEmail(text)
    return (
      <div className={className}>
        <div className="alert__wrapper">
          <AlertHeader title={title} headerComponent={headerComponent} />
          <AlertBody text={modified} bodyComponent={bodyComponent} />
        </div>
      </div>
    )
  }
}

Alert.defaultProps = {
  timeout: 5000,
  type: "info",
}

Alert.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  timeout: PropTypes.number,
  headerComponent: PropTypes.element,
  bodyComponent: PropTypes.element,
  renderFooter: PropTypes.func,
}

export default Alert

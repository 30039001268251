/**
 * Main vantage theme colors and variables
 * @type {{}}
 */

const main = {}

const charcoalGrey = "#323c44"
const slate = "#4a5b68"
const black = "#303030"
const vantageHighlight = "#4FE3C2"
const vantageHighlightDark = "#39b69b"
const white = "#ffffff"
const bluishGrey = "#7d8b96"
const lightBlueGrey = "#c4cace"
const lightGreyBlue = "#adb1b5"
const watermelon = "#ff495c"
const veryLightPink = "#efefef"
const veryLightPink2 = "#e0e0e0"
// const dark80 = "rgba(20, 27, 33, 0.8)";
// const lightOliveGreen15 = "rgba(156, 208, 75, 0.15)";
const lightOliveGreen = "#9cd04b"
const nastyGreen = "#7ca63c"
const success = "#9CD04B"
const failure = "#FF495C"
const emailBlue = "#015e85"
const emailPink = "#604658"
const lightGreen = "#e5fbf6"
const lightPink = "#fdecee"

main.colors = {
  textPrimary: charcoalGrey,
  textSecondary: bluishGrey,
  textSecondaryDark: slate,
  textSection: slate,
  vantageHighlight: vantageHighlight,
  primary: vantageHighlight,
  primaryDark: vantageHighlightDark,
  secondary: vantageHighlight,
  subheader: slate,
  profileHeader: charcoalGrey,
  success: nastyGreen,
  danger: watermelon,
  warning: "orange",
  error: watermelon,
  disabled: lightBlueGrey,
  info: bluishGrey,
  light: white,
  border: lightBlueGrey,
  border2: lightGreyBlue,
  border3: veryLightPink2,
  tableHover: veryLightPink,
  inactive: lightGreyBlue,
  formBG: "#f6f6f6",
  tabSelectedBG: veryLightPink,
  lightBG: veryLightPink,
  green: lightOliveGreen,
  activeRole: success,
  archivedRole: failure,
  emailEditorBlack: charcoalGrey,
  emailEditorBlue: emailBlue,
  emailEditorPink: emailPink,
  tagGreen: lightGreen,
  tagPink: lightPink,
}

main.mobileBreakpoints = {
  xs: 320,
  sm: 600,
  md: 1000,
  lg: 1200,
  xl: 1500,
}

main.typography = {
  fonts: {
    regular: "Europa, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif",
  },
  weights: {
    bold: "700",
    regular: "400",
    light: "300",
  },
  sizes: {
    hero: "50px",
    subHero: "16px",
    step: "30px",
    title: "21px",
    sectionTitle: "24px",
    pageTitle: "30px",
    placeholder: "16px",
    text: "16px",
    inputText: "16px",
    buttonLabel: "14px",
    buttonLabelSmall: "12px",
    buttonLink: "12px",
    hint: "14px",
    hintSmall: "13px",
    message: "14px",
    tableCell: "14px",
    sideMenu: "14px",
    successInputHelp: "14px",
    errorInputHelp: "14px",
  },
  lineHeights: {
    hero: "36px",
    subHero: "24px",
    step: "36px",
    title: "28px",
    sectionTitle: "36px",
    pageTitle: "36px",
    placeholder: "24px",
    text: "24px",
    inputText: "24px",
    buttonLabel: "24px",
    buttonLabelSmall: "24px",
    buttonLink: "18px",
    hint: "20px",
    hintSmall: "20px",
    message: "20px",
    tableCell: "20px",
    sideMenu: "20px",
    successInputHelp: "24px",
    errorInputHelp: "24px",
  },
  colors: {
    hero: white,
    subHero: white,
    step: white,
    title: charcoalGrey,
    sectionTitle: slate,
    pageTitle: slate,
    placeholder: bluishGrey,
    text: charcoalGrey,
    inputText: black,
    buttonLabel: white,
    buttonLink: bluishGrey,
    hint: bluishGrey,
    hintSmall: bluishGrey,
    message: charcoalGrey,
    sideMenu: bluishGrey,
    successInputHelp: nastyGreen,
    errorInputHelp: watermelon,
  },
  invertedColors: {
    hero: white,
    subHero: white,
    step: white,
    title: bluishGrey,
    sectionTitle: slate,
    pageTitle: slate,
    placeholder: charcoalGrey,
    text: bluishGrey,
    inputText: black,
    buttonLabel: white,
    buttonLink: charcoalGrey,
    hint: charcoalGrey,
    sideMenu: charcoalGrey,
    successInputHelp: nastyGreen,
    errorInputHelp: watermelon,
  },
  mobileSizes: {
    md: {
      hero: "20px",
      subHero: "14px",
      step: "18px",
      title: "18px",
      sectionTitle: "20px",
      pageTitle: "20px",
      placeholder: "16px",
      text: "16px",
      inputText: "16px",
      buttonLabel: "14px",
      buttonLabelSmall: "12px",
      buttonLink: "12px",
      hint: "12px",
      sideMenu: "16px",
      successInputHelp: "12px",
      errorInputHelp: "12px",
    },
  },
  mobileLineHeights: {
    md: {
      hero: "36px",
      subHero: "20px",
      step: "18px",
      title: "28px",
      sectionTitle: "24px",
      pageTitle: "36px",
      placeholder: "24px",
      text: "24px",
      inputText: "24px",
      buttonLabel: "24px",
      buttonLabelSmall: "24px",
      buttonLink: "18px",
      hint: "16px",
      sideMenu: "20px",
      successInputHelp: "16px",
      errorInputHelp: "16px",
    },
  },
}

main.selectField = {
  standard: {
    border: main.colors.border,
    background: main.colors.light,
    color: main.colors.textPrimary,
    optionColor: main.colors.textPrimary,
    separatorWidth: "0",
    iconColor: main.colors.border,
    iconColorDark: main.colors.textSecondary,
    separatorColor: "white",
    fontSize: "16px",
    fontWeight: "normal",
    letterSpacing: "normal",
    textTransform: "normal",
    marginLeft: "13px",
  },
  standardRolesScreeningVariant: {
    border: main.colors.border,
    background: main.colors.light,
    color: main.colors.textPrimary,
    optionColor: main.colors.textPrimary,
    separatorWidth: "0",
    iconColor: main.colors.border,
    iconColorDark: main.colors.textSecondary,
    separatorColor: "white",
    fontSize: "15.5px",
    fontWeight: "normal",
    letterSpacing: "normal",
    textTransform: "normal",
    marginLeft: "6px",
  },
  bold: {
    border: main.colors.border,
    background: main.colors.light,
    color: main.colors.textPrimary,
    optionColor: main.colors.textPrimary,
    separatorWidth: "0",
    iconColor: main.colors.border,
    iconColorDark: main.colors.textSecondary,
    separatorColor: "white",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "normal",
    textTransform: "normal",
    marginLeft: "13px",
  },
  primary: {
    border: main.colors.primary,
    background: main.colors.primary,
    color: main.colors.light,
    optionColor: main.colors.light,
    separatorWidth: "1px",
    iconColor: main.colors.light,
    iconColorDark: main.colors.light,
    separatorColor: "white",
    fontSize: "16px",
    fontWeight: "normal",
    letterSpacing: "normal",
    textTransform: "normal",
    marginLeft: "13px",
  },
  primarySubstage: {
    border: main.colors.primary,
    background: main.colors.primary,
    color: main.colors.light,
    optionColor: main.colors.textPrimary,
    separatorWidth: "1px",
    iconColor: main.colors.light,
    iconColorDark: main.colors.light,
    separatorColor: "white",
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: "3px",
    textTransform: "normal",
    marginLeft: "13px",
  },
  control: {
    border: main.colors.border,
    background: main.colors.light,
    color: main.colors.textSecondary,
    optionColor: main.colors.textPrimary,
    separatorWidth: "1px",
    iconColor: main.colors.textSecondary,
    iconColorDark: main.colors.textSecondary,
    separatorColor: main.colors.border,
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "3px",
    textTransform: "uppercase",
    marginLeft: "13px",
  },
}

main.buttons = {
  borderRadius: "24px",
  width: "216px",
  heightSmall: "40px",
  heightVerySmall: "30px",
  medium: "40px",
  height: "48px",
  primary: {
    border: "none",
    background: main.colors.primary,
    color: main.colors.light,
  },
  reject: {
    border: "none",
    background: main.colors.info,
    color: main.colors.light,
  },
  secondary: {
    border: "none",
    background: main.colors.textPrimary,
    color: main.colors.light,
  },
  applications: {
    border: "none",
    background: main.colors.light,
    color: main.colors.textPrimary,
  },
  crop: {
    border: "none",
    background: main.colors.border,
    color: main.colors.textSecondaryDark,
  },
  vantage: {
    border: "none",
    background: main.colors.vantageHighlight,
    color: main.colors.secondary,
  },
  action: {
    border: `1px solid ${main.colors.border2}`,
    background: "transparent",
    color: main.colors.border2,
  },
  info: {
    border: "none",
    background: "#e0e0e0",
    color: main.colors.textSecondaryDark,
  },
  link: {
    border: "none",
    background: "transparent",
    color: main.colors.textSecondary,
  },
  linkLight: {
    border: "none",
    background: "transparent",
    color: main.colors.light,
  },
  linkDark: {
    border: "none",
    background: "transparent",
    color: main.colors.textPrimary,
  },
  linkDisabled: {
    border: "none",
    background: "transparent",
    color: main.colors.textSecondary,
  },
  gridButton: {
    border: `1px solid ${main.colors.border}`,
    background: "transparent",
    color: main.colors.textSecondary,
  },
  candidateProfileButton: {
    border: `1px solid ${main.colors.border3}`,
    background: main.colors.border3,
    color: main.colors.textSecondaryDark,
  },
  light: {
    border: `1px solid ${main.colors.light}`,
    background: main.colors.light,
    color: main.colors.textPrimary,
  },

  hover: {
    primary: {
      border: "none",
      background: main.colors.textPrimary,
      color: main.colors.light,
    },
    reject: {
      border: "none",
      background: main.colors.textPrimary,
      color: main.colors.light,
    },
    secondary: {
      border: "none",
      background: main.colors.textPrimary,
      color: main.colors.light,
    },
    applications: {
      border: "none",
      background: main.colors.textSecondary,
      color: main.colors.light,
    },
    vantage: {
      border: "none",
      background: main.colors.textPrimary,
    },
    crop: {
      border: "none",
      background: main.colors.border3,
      color: main.colors.textSecondaryDark,
    },
    action: {
      border: main.colors.border2,
      background: main.colors.border2,
      color: white,
    },
    info: {
      border: "none",
      background: main.colors.textPrimary,
      color: main.colors.light,
    },
    link: {
      border: "none",
      background: "transparent",
      color: main.colors.textPrimary,
    },
    linkLight: {
      border: "none",
      background: "transparent",
      color: main.colors.textSecondary,
    },
    linkDark: {
      border: "none",
      background: "transparent",
      color: main.colors.textSecondary,
    },
    linkDisabled: {
      border: "none",
      background: "transparent",
      color: main.colors.textSecondary,
    },
    gridButton: {
      border: `1px solid ${main.colors.border}`,
      background: main.colors.border,
      color: "#ffffff",
    },
    candidateProfileButton: {
      border: `1px solid ${main.colors.border3}`,
      background: main.colors.border3,
      color: main.colors.textSecondaryDark,
    },
  },
  disabled: {
    opacity: 0.4,
    background: main.colors.disabled,
    color: main.colors.secondary,
  },
}

main.inputs = {
  background: main.colors.secondary,
  width: "300px",
  height: "50px",
  color: main.colors.info,
  placeholderColor: main.colors.primary,
  border: "1px solid #ccc",
  focus: {
    border: "1px solid #ccc",
    boxShadow: "none",
    background: main.colors.secondary,
    color: main.colors.info,
  },
}

main.menuItems = {
  active: main.colors.primaryDark,
  selected: main.colors.primaryDark,
  inactive: main.colors.inactive,
}
main.menuItemsText = {
  active: main.colors.textPrimary,
  selected: main.colors.primaryDark,
  inactive: main.colors.textSecondary,
}

main.widths = {
  small: "100px",
  smallMedium: "130px",
  medium: "216px",
  // Added mediumLarge to replace medium2 to keep to the naming convention.
  // I've replaced medium2 in the all teh places in the code I could find it
  // But kept medium2 for now just in case.
  mediumLarge: "332px",
  medium2: "332px",
  large: "458px",
  search: "448px",
  fullWidth: "100%",
}

export default main

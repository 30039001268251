/**
 * App Component
 * Entry point of the app
 * This is where the high level components like redux store, routing, error handling are initialized
 * */
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React, { Component } from "react"
import { Provider } from "react-redux"
import CookieHelper from "utils/cookie"
import { ConnectedRouter } from "react-router-redux"
import store, { history, persistor } from "./store"
import WrapperLayout from "./layouts/WrapperLayout/container"
import "./services/interceptors"
import "normalize.css/normalize.css"
import GlobalStyle from "./styles/globals"
import AppRoutes from "./routes/AppRoutes/container"
import ErrorWrapper from "components/ErrorWrapper"
import { getCurrentSize } from "utils/utils"
import { PersistGate } from "redux-persist/integration/react"
import AppLoader from "components/AppLoader"
import "./styles/tippy.css"
import CookieBanner from "components/CookieBanner/CookieBanner"
import ManageCookies from "components/CookieBanner/ManageCookies"
import UncaughtError from "components/UncaughtError"
import { HelmetProvider } from "react-helmet-async"

// Size context is a way of knowing what type of screen we are currently on (xs, sm, md, lg, xl)
// Import it to screens to use the value
export const SizeContext = React.createContext("sm")

class App extends Component {
  state = {
    size: "sm",
    manageCookiesOpen: false,
  }

  closeManageCookies = () => {
    this.setState({ manageCookiesOpen: false })
    CookieHelper.save("showCookieBanner", "true", 30)
  }

  manageCookies = () => {
    CookieHelper.save("showCookieBanner", "false", 30)
    this.setState({ manageCookiesOpen: true })
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true, error, stack: error.stack })
  }

  componentDidMount() {
    // persistor.purge();
    window.addEventListener("resize", this.reCalculateSize)
    this.reCalculateSize()

    if (
      !CookieHelper.load("showCookieBanner") ||
      !CookieHelper.load("acceptCookies")
    ) {
      CookieHelper.removeall()
      CookieHelper.save("showCookieBanner", "true", 30)
      CookieHelper.save("acceptCookies", "false", 30)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reCalculateSize)
  }

  reCalculateSize = () => {
    this.setState({ size: getCurrentSize() })
  }

  render() {
    if (this.state.hasError) {
      return <UncaughtError error={this.state.error} stack={this.state.stack} />
    }

    return (
      <Provider store={store}>
        <PersistGate loading={<AppLoader />} persistor={persistor}>
          <HelmetProvider>
            <ConnectedRouter history={history}>
              <WrapperLayout>
                <GlobalStyle />
                <ErrorWrapper store={store}>
                  <SizeContext.Provider value={this.state.size}>
                    <AppRoutes />
                  </SizeContext.Provider>
                </ErrorWrapper>
              </WrapperLayout>
            </ConnectedRouter>
            <CookieBanner
              display={CookieHelper.load("showCookieBanner")}
              manageCookies={this.manageCookies}
            />
            <ManageCookies
              display={this.state.manageCookiesOpen}
              closeManageCookies={this.closeManageCookies}
            />
          </HelmetProvider>
        </PersistGate>
      </Provider>
    )
  }
}

export default App

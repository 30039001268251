/**
 * Switch field component - Usually for a Yes/No type of answers
 */

import React from "react"
import PropTypes from "prop-types"
import {
  ControlWrapper,
  SwitchWrapper,
  Label,
  Hint,
  Option,
  ErrorMessage,
  OptionalWrapper,
} from "./styles"
import Text from "components/Text"
import OptionalLabel from "components/OptionalLabel"
import { SideLabel } from "styles/Common"

class SwitchField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      touched: false,
      selected: null,
    }
  }

  handleSelect = optionId => {
    const { options, onChange, id, callback, disabled } = this.props

    if (!disabled) {
      const sameOptionId = optionId === this.state.selected

      if (sameOptionId) {
        this.setState(
          {
            selected: undefined,
          },
          callback
        )
      } else {
        this.setState(
          {
            selected: optionId,
          },
          callback
        )
      }

      if (onChange)
        if (sameOptionId) {
          onChange(id, undefined, callback)
        } else {
          onChange(
            id,
            options.filter(i => i.value === optionId)[0].value,
            callback
          )
        }
    }
  }

  handleKeyPressSelect = (optionId, event) => {
    const { options, onChange, id, callback, disabled } = this.props

    if (!disabled && event.key === "Enter") {
      const sameOptionId = optionId === this.state.selected

      if (sameOptionId) {
        this.setState(
          {
            selected: undefined,
          },
          callback
        )
      } else {
        this.setState(
          {
            selected: optionId,
          },
          callback
        )
      }

      if (onChange)
        if (sameOptionId) {
          onChange(id, undefined, callback)
        } else {
          onChange(
            id,
            options.filter(i => i.value === optionId)[0].value,
            callback
          )
        }
    }
  }

  componentDidMount() {
    const { value } = this.props
    if (value || value === false) {
      this.setState({
        selected: value.toString(),
      })
    }
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      if (nextProps.value) {
        this.setState({
          selected: nextProps.value.toString(),
        })
      } else {
        this.setState({
          selected: undefined,
        })
      }
    }
  }

  render() {
    const {
      options,
      label,
      hint,
      optional,
      id,
      error,
      errorMessage,
      inlineLabels,
      forceErrorDisplay,
      labelSideText,
      secondPartText,
    } = this.props

    return (
      <div className={error && forceErrorDisplay ? "hasError" : null}>
        {label && (
          <Label as={"label"} htmlFor={id} inlineLabels={inlineLabels}>
            {label}
            {labelSideText && <SideLabel>{labelSideText}</SideLabel>}
            {optional && (
              <OptionalWrapper>
                <OptionalLabel />
              </OptionalWrapper>
            )}
          </Label>
        )}

        {hint && (
          <Hint variant={"hint"} inlineLabels={inlineLabels}>
            {hint}
          </Hint>
        )}

        {secondPartText && (
          <Label as={"label"} htmlFor={id} inlineLabels={inlineLabels}>
            {secondPartText}
          </Label>
        )}

        <ControlWrapper>
          <SwitchWrapper id={id} numOfOptions={options.length}>
            {options
              ? options.map((i, index) => {
                  return (
                    <Option
                      tabIndex="0"
                      hasError={error && forceErrorDisplay}
                      onClick={() => {
                        this.handleSelect(i.value.toString())
                      }}
                      onKeyPress={event => {
                        this.handleKeyPressSelect(i.value.toString(), event)
                      }}
                      key={i.id}
                      id={i.id}
                      first={index === 0}
                      last={index === options.length - 1}
                      selected={
                        i.value === this.state.selected ||
                        i.value === "" + this.state.selected
                      }
                    >
                      <label htmlFor={i.id}>{i.label}</label>
                    </Option>
                  )
                })
              : null}
          </SwitchWrapper>
          {forceErrorDisplay && error && errorMessage ? (
            <ErrorMessage>
              <Text variant={"errorInputHelp"} id={`error-${id}`}>
                {errorMessage}
              </Text>
            </ErrorMessage>
          ) : null}
        </ControlWrapper>
      </div>
    )
  }
}

SwitchField.propTypes = {
  options: PropTypes.array,
  error: PropTypes.bool,
  errorMessage: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  validationType: PropTypes.oneOf(["onBlur", "onSubmit"]),
}

SwitchField.defaultProps = {
  options: [
    { id: "yes", value: "true", label: "Yes" },
    { id: "no", value: "false", label: "No" },
  ],
}

export default SwitchField

/**
 * ErrorWrapper
 * Wraps the whole application, catches any errors and logs it to Sentry
 */

import React, { Component } from "react"

export class ErrorWrapper extends Component {
  render() {
    return <div>{this.props.children}</div>
  }
}

export default ErrorWrapper

import React, { Component } from "react"

import { RareLogo, Sorry, Instruction, Robot, MainError } from "./style"

class UncaughtError extends Component {
  render() {
    // You can render any custom fallback UI
    return (
      <MainError>
        <div>
          <RareLogo>
            <img
              alt="Rare"
              src="https://rarewebsite.s3-eu-west-1.amazonaws.com/rare-logo.png"
            ></img>
          </RareLogo>

          <Sorry>Sorry. There's been a problem.</Sorry>
          <Instruction>
            Please contact your Rare Client Success Manager for support or visit
            our FAQ page -
            <a
              rel="noreferrer"
              href={"https://candidats.io/faq"}
              target="_blank"
            >
              candidats.io/faq
            </a>
          </Instruction>
        </div>

        <Robot>
          <img
            alt="Broken Robot"
            src="https://rarewebsite.s3-eu-west-1.amazonaws.com/robot.png"
          />
        </Robot>
      </MainError>
    )
  }
}

export default UncaughtError

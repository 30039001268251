import styled from "styled-components"
import theme from "styles/theme-main"
import Text from "components/Text"

export const OptionLabelContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 9px;
`

export const OptionLabel = styled(Text)`
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  color: ${theme.colors.textSecondary};
  font-size: 14px;
  line-height: 14px;
`

import CookieHelper from "utils/cookie"
import { isInternalMode } from "utils/productUtils"
import {
  SECTOR_CHAMBERS_ID,
  SECTOR_LAW_ID,
  SECTOR_LAW_SCOTLAND_ID,
  SECTOR_CITY_LAW_ID,
  SOVA_PROJECT_CODE,
  SOVA_PROJECT_CODES_KEY,
  UNIVERSITY_GROUPS_UK,
  UNIVERSITY_GROUPS_OZ,
} from "utils/constants"

import { getFormConfigurationFromOrganization } from "utils/configUtils"

import { generateOption } from "utils/constants"
import { isHREnabled, toFixedIfNumberHasDecimals } from "utils/utils"
import { getMaxPossibleScore } from "utils/assessmentUtils"

export const isLawClient = organization =>
  organization &&
  organization.sector &&
  [SECTOR_CITY_LAW_ID, SECTOR_LAW_ID, SECTOR_LAW_SCOTLAND_ID].indexOf(
    organization.sector.id
  ) !== -1

export const isChambersClient = organization =>
  organization &&
  organization.sector &&
  organization.sector.id === SECTOR_CHAMBERS_ID

export const isLegalClient = organization =>
  isLawClient(organization) || isChambersClient(organization)

export const isNonLegalClient = organization =>
  !isLawClient(organization) && !isChambersClient(organization)

export const getAccountType = organization => {
  return isNonLegalClient(organization) || isOZAPI(organization)
    ? "Candid"
    : isInternalMode()
    ? "Rare"
    : "Vantage"
}

export const getClientTimezones = organization => {
  const oconfiguration = getFormConfigurationFromOrganization(organization)

  return oconfiguration.textArrays?.timezones
    ? oconfiguration.textArrays?.timezones.map(it => {
        const [id, label] = it.split(";")
        return generateOption(id, id, label)
      })
    : []
}

export const getOrgTypePlural = organization => {
  return isNonLegalClient(organization)
    ? "Companies"
    : isLawClient(organization)
    ? "Firms"
    : "Chambers"
}

export const getOrgType = organization => {
  return isNonLegalClient(organization)
    ? "Company"
    : isLawClient(organization)
    ? "Firm"
    : "Chambers"
}

export const lawMap = {
  "Graduate scheme": "Training contract",
  Internship: "Vacation scheme",
}

export const chambersMap = {
  "Graduate scheme": "Pupillage",
  Internship: "Mini-pupillage",
  Other: "Other",
}

export const ozMap = {
  "Graduate scheme": "Graduate Program",
  Internship: "Clerkship",
  "Year in industry": "School Program",
  Other: "Other",
}

export const getRoleTypes = (organization, roleTypes) => {
  if (isOZAPI(organization)) {
    return roleTypes
      .filter(x => ozMap[x.value])
      .map(roleType => ({
        ...roleType,
        value: formatRoleType(organization, roleType.value),
        label: formatRoleType(organization, roleType.value),
      }))
  } else if (isLegalClient(organization)) {
    const filteredTypes = roleTypes.filter(x =>
      isChambersClient(organization) ? chambersMap[x.value] : true
    )
    return filteredTypes.map(roleType => ({
      ...roleType,
      value: formatRoleType(organization, roleType.value),
      label: formatRoleType(organization, roleType.value),
    }))
  } else {
    return roleTypes
  }
}

export const formatRoleType = (organization, roleType) => {
  if (isOZAPI(organization)) {
    return ozMap[roleType] || roleType
  }
  if (isLawClient(organization)) {
    return lawMap[roleType] || roleType
  } else if (isChambersClient(organization)) {
    return chambersMap[roleType] || roleType
  } else {
    return roleType
  }
}

export const isOZAPI = organization => organization?.countryBase === "OZ"

export const isHKAPI = organization => organization?.countryBase === "HK"

export const isUKAPI = organization =>
  !organization ||
  !organization.countryBase ||
  organization.countryBase === "UK"

export const isUKorHKAPI = organization =>
  isUKAPI(organization) || isHKAPI(organization)

export const hasSovaProjectCodes = (scoreDef, organization) => {
  return scoreDef?.metrics?.find(metric =>
    isSOVAProjectCodeMetric(metric, organization)
  )
}

export const isSOVAProjectCodeMetric = (metric, organization) => {
  return metric === SOVA_PROJECT_CODE && !!getProjectCodes(organization)
}

export const getProjectCodes = organization => {
  return getFormConfigurationFromOrganization(organization)?.textArrays?.[
    SOVA_PROJECT_CODES_KEY
  ]?.map(code => {
    const tokens = code.split(":")
    return generateOption(tokens[0], tokens[0], tokens[1])
  })
}

export const getUniversityGrouping = organization =>
  isOZAPI(organization) ? UNIVERSITY_GROUPS_OZ : UNIVERSITY_GROUPS_UK

const getScore = (scores, key) =>
  parseFloat(scores?.find(score => score.key === key)?.value) || 0

const getScoreSums = (scores, scoreKeys) => {
  return scoreKeys.reduce(
    (currentSum, scoreKey) => currentSum + getScore(scores, scoreKey),
    0
  )
}

export const getAdjustedTotalScore = (
  organization,
  scores,
  totalScore,
  scoreDef
) => {
  const oconfiguration = getFormConfigurationFromOrganization(organization)
  const totalPossibleScore = getMaxPossibleScore(scoreDef)

  const scoresForStage = scores?.filter(x => x.stageId === scoreDef.stageId)

  if (
    !isHREnabled(oconfiguration, "customMatrixScore") ||
    !scoresForStage?.find(score => score.key === "Raw academic performance")
  ) {
    return {
      score: totalScore,
      middle: "/",
      total: totalPossibleScore,
    }
  }
  const totalOverallScore = getScoreSums(scoresForStage, [
    "Raw academic performance",
    "PI/Flags addition",
    "Mitigating addition",
    "Second career",
  ])
  const totalCuriosity =
    getScoreSums(scoresForStage, [
      "Enthusiasm for law - active participation",
      "Enthusiasm for law - insight",
    ]) / 2
  const totalOrg =
    getScoreSums(scoresForStage, ["Writing quality", "Writing accuracy"]) / 2

  const cappedScore = Math.min(4, totalOverallScore)

  const score = cappedScore * 1.5 + (totalCuriosity + totalOrg) * 0.75
  const scoreWithDecimals = parseFloat(toFixedIfNumberHasDecimals(score))

  return {
    score: scoreWithDecimals,
    middle: "/",
    total: 12,
  }
}

export const isGTMTracker = organization => {
  const configuration = getFormConfigurationFromOrganization(organization)
  if (CookieHelper.load("acceptCookies")) {
    if (configuration.text?.gtmId || configuration.text?.gtmScriptUrl) {
      return {
        gtmId: configuration.text?.gtmId,
        scriptUrl: configuration.text?.gtmScriptUrl,
        pixelUrl: configuration.text?.pixelUrl,
      }
    }
  }
}

export const isFBTracker = organization => {
  const configuration = getFormConfigurationFromOrganization(organization)
  if (CookieHelper.load("acceptCookies")) {
    return configuration.text?.facebookPixelId
  }
}

import { createGlobalStyle } from "styled-components"
import EuropaRegularEOT from "assets/fonts/Europa-Regular.eot"
import EuropaRegularWOFF from "assets/fonts/Europa-Regular.woff"
import EuropaRegularWOFF2 from "assets/fonts/Europa-Regular.woff2"
import EuropaRegularTTF from "assets/fonts/Europa-Regular.ttf"
import EuropaRegularSVG from "assets/fonts/Europa-Regular.svg"

import EuropaBoldEOT from "assets/fonts/Europa-Bold.eot"
import EuropaBoldWOFF from "assets/fonts/Europa-Bold.woff"
import EuropaBoldWOFF2 from "assets/fonts/Europa-Bold.woff2"
import EuropaBoldTTF from "assets/fonts/Europa-Bold.ttf"
import EuropaBoldSVG from "assets/fonts/Europa-Bold.svg"

import EuropaLightEOT from "assets/fonts/Europa-Light.eot"
import EuropaLightWOFF from "assets/fonts/Europa-Light.woff"
import EuropaLightWOFF2 from "assets/fonts/Europa-Light.woff2"
import EuropaLightTTF from "assets/fonts/Europa-Light.ttf"
import EuropaLightSVG from "assets/fonts/Europa-Light.svg"

export const GlobalStyle = createGlobalStyle`
  html,body {
      height: 100% !important
  }
  
  @font-face {
    font-family: Europa;
    src: url(${EuropaRegularEOT});
    src: url(${EuropaRegularEOT}?#iefix) format("embedded-opentype"),url(${EuropaRegularWOFF2}) format("woff2"),url(${EuropaRegularWOFF}) format("woff"),url(${EuropaRegularTTF}) format("truetype"),url(${EuropaRegularSVG}#Europa-Regular) format("svg");
    font-weight: 400;
    font-style: normal
  }

@font-face {
    font-family: Europa;
    src: url(${EuropaBoldEOT});
    src: url(${EuropaBoldEOT}?#iefix) format("embedded-opentype"),url(${EuropaBoldWOFF2}) format("woff2"),url(${EuropaBoldWOFF}) format("woff"),url(${EuropaBoldTTF}) format("truetype"),url(${EuropaBoldSVG}#Europa-Regular) format("svg");
    font-weight: 700;
    font-style: normal
  }

@font-face {
    font-family: Europa;
    src: url(${EuropaLightEOT});
    src: url(${EuropaLightEOT}?#iefix) format("embedded-opentype"),url(${EuropaLightWOFF2}) format("woff2"),url(${EuropaLightWOFF}) format("woff"),url(${EuropaLightTTF}) format("truetype"),url(${EuropaLightSVG}#Europa-Regular) format("svg");
    font-weight: 300;
    font-style: normal
  }

  body {
    width: 100%;
    height: 100%;
    overflow-y: visible;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: Europa, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial,
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  p, li {
    font-size: 16px;
    line-height: 24px;
    color: #323c44;
    font-weight: 400;
  }
  
  p.nomargin {
    margin: 0;
  }
  
  li a, p a {
    display:inline-lock;
    text-decoration: none;
    padding-bottom: 1px;
    color: inherit;
    border-bottom: 1px dotted  #7d8b96;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  
  li a:hover, p a:hover {
    color: #7d8b96;
    border-bottom: 1px dotted  #7d8b96;

  }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale
  }
 
 ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #a29f99;
  }
  
   /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color:#323c44 !important;
    -webkit-color: #323c44 !important;
    -webkit-background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 0 transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
 
 /* This next line is the CSS for partner logos for the Rare Lateral Addon */
 div.rllc{display:flex;align-items:flex-start;flex-wrap:wrap;justify-content:space-between}div.rllc div{width:33.3333%;-ms-flex-pack:center;-webkit-box-pack:center;justify-content:center}div.rllc div div{height:150px;border:1px solid #c4cace;margin:10px;position:relative;width: auto;}div.rllc div div img{max-width:80%;height:auto;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto}
 @media screen and (max-width: 800px){div.rllc div{width:50%;}} 
  
`

export default GlobalStyle

/**
 * Account reducer
 * Handles login/logout actions
 */

import { createAction, handleActions } from "redux-actions"
import AuthService from "services/auth"
import { actions as userActions } from "modules/user"
import { actions as dataActions } from "modules/data"
import CookieHelper from "../utils/cookie"
import { emitProcess } from "utils/utils"
import { isProfileMode } from "utils/productUtils"

// ------------------------------------
// Constants
// ------------------------------------
export const LOGIN_LOADING = "LOGIN_LOADING"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const SET_OTP = "SET_OTP"
export const INIT_OTP = "INIT_OTP"

// ------------------------------------
// Actions
// ------------------------------------
const loginSuccess = createAction(LOGIN_SUCCESS)
const logoutSuccess = createAction(LOGOUT_SUCCESS)
const setOtp = createAction(SET_OTP)
const initOtp = createAction(INIT_OTP)

const processLogin = async (dispatch, response) => {
  AuthService.setAuthInfo(response.data)
  dispatch(emitProcess("success", "login"))
  return dispatch(userActions.getUser(true)).then(x => {
    if (x.payload.otpCookie) {
      CookieHelper.save(`otpcookie-${x.payload.email}`, x.payload.otpCookie, 30)
    }
    return dispatch(loginSuccess(response.data))
  })
}

const processToken = response => {
  return dispatch => {
    return processLogin(dispatch, response)
  }
}

/**
 * Login
 * Login and dispatch user load
 * @param username
 * @param password
 * @returns {function(*): *}
 */
const login = (username, password, organization, otp) => {
  return dispatch => {
    dispatch(emitProcess("loading", "login"))
    return AuthService.login(
      username,
      password,
      otp,
      isProfileMode() ? undefined : organization
    ).then(
      response => {
        if (response.status !== 206) {
          return processLogin(dispatch, response)
        } else {
          dispatch(emitProcess("loading", "otp"))
          return dispatch(setOtp())
        }
      },
      err => {
        if (err.response.data.error_description === "Invalid login cookie.") {
          CookieHelper.remove(`otpcookie-${username}`)
          dispatch(emitProcess("loading", "otp"))
          return dispatch(setOtp())
        }
        return dispatch(emitProcess("error", "login", err))
      }
    )
  }
}

const loginByToken = (token, organization) => {
  return dispatch => {
    dispatch(emitProcess("loading", "login"))

    return AuthService.loginByToken(token, organization).then(
      response => {
        return processLogin(dispatch, response)
      },
      err => {
        return dispatch(emitProcess("error", "login", err))
      }
    )
  }
}

const loginOAUth = (token, organization) => {
  return dispatch => {
    dispatch(emitProcess("loading", "login"))
    return AuthService.loginOAuth(token, organization).then(
      response => {
        AuthService.setAuthInfo(response.data)
        dispatch(emitProcess("success", "login"))
        dispatch(userActions.getUser())
        return dispatch(loginSuccess(response.data))
      },
      err => {
        return dispatch(emitProcess("error", "login", err))
      }
    )
  }
}

/**
 * Logout
 * @returns {function(*): *}
 */
const logout = (link, pathname, search) => {
  return dispatch => {
    dispatch(emitProcess("loading", "login"))
    dispatch(userActions.clearUser())
    dispatch(dataActions.setOrganization({}))
    AuthService.clearAuthInfo()
    dispatch(logoutSuccess())
    window.onbeforeunload = null
    if (link) {
      setTimeout(() => {
        window.location.href = link
      }, 500)
    } else if (pathname) {
      setTimeout(() => {
        window.location.href = `/login?url=${pathname}&search=${search}`
      }, 500)
    }
  }
}

export const actions = {
  login,
  loginByToken,
  processToken,
  logout,
  loginOAUth,
  logoutSuccess,
  loginSuccess,
  initOtp,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isLoggedIn: false,
  loading: false,
  errors: null,
  setOtp: false,
  data: {},
}

export default handleActions(
  {
    LOGOUT_SUCCESS: state => {
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        errors: null,
        data: {},
      }
    },

    LOGIN_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        data: payload,
        errors: null,
      }
    },
    INIT_OTP: (state, { payload }) => {
      return {
        ...state,
        setOtp: false,
      }
    },
    SET_OTP: (state, { payload }) => {
      return {
        ...state,
        setOtp: true,
      }
    },
  },
  initialState
)

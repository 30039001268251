import React from "react"
import styled from "styled-components"
import CookieHelper from "utils/cookie"
import { ThemeProvider } from "styled-components"
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles"
import { convertMUI } from "utils/utils"
import theme from "styles/theme-main"
import Icon from "components/Icon"
import Button from "components/Button"
import SwitchField from "components/SwitchField"

const ModalMask = styled.div`
  position: fixed;
  z-index: 9991;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  transition: opacity 0.3s ease;
  background-color: rgba(255, 255, 255, 0.95);
  overflow: hidden;
`

const ModalWrapper = styled.div`
  vertical-align: middle;
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9991;
  overflow-y: scroll;
`

const CloseIconWrapper = styled.div`
  color: ${theme.colors.textPrimary};
  cursor: pointer;
  position: absolute;
  right: 124px;
  top: 84px;
  z-index: 9995;
  height: 24px;
  width: 24px;
`

const ModalContainer = styled.div`
  width: 100%;
  margin: 0px auto;
  padding: 0;
  background-color: transparent;
  transition: all 0.3s ease;
  opacity: 1;
  padding: 0 15.6% !important;
`

const Title = styled.h2`
  font-size: 56px;
  font-weight: 700;
  line-height: 1.29;
  letter-spacing: 0.8px;
  margin: 88px 0 24px;
  color: ${theme.colors.textPrimary};
`

const P = styled.p`
  color: ${theme.colors.textPrimary};
  font-size: 21px;
  line-height: 1.52;
  letter-spacing: 0.4px;
  margin-bottom: 12px;

  a {
    color: ${theme.colors.vantageHighlight};
  }

  a:hover {
    text-decoration: none;
  }
`

const H3 = styled.h3`
  color: ${theme.colors.textPrimary};
  font-size: 21px;
  line-height: 1.52;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
  font-weight: 700;
`

const SwitchFieldWrapper = styled.div`
  margin-top: 30px;
`

const CookieSection = styled.div`
  margin-bottom: 50px;
`

export class CookieBanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      analyticsCookies: null,
    }
  }

  handleAnalyticsSwitchChange = (name, value) => {
    this.setState({ analyticsCookies: value })
  }

  SaveCookiePreferences = () => {
    if (this.state.analyticsCookies === "true") {
      CookieHelper.save("showCookieBanner", "false", 30)
      CookieHelper.save("acceptCookies", "true", 30)
    } else {
      CookieHelper.save("showCookieBanner", "false", 30)
      CookieHelper.save("acceptCookies", "false", 30)
    }
    window.location.reload(false)
  }

  render() {
    const { display, closeManageCookies } = this.props

    const muitheme = createTheme(convertMUI(theme))

    return display ? (
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muitheme}>
          <ModalMask />
          <ModalWrapper>
            <CloseIconWrapper onClick={closeManageCookies}>
              <Icon variant={"cross_sml_slate"} />
            </CloseIconWrapper>
            <ModalContainer>
              <Title>Manage Cookies</Title>
              <hr style={{ margin: "30px auto 30px 0" }} />
              <CookieSection>
                <P>
                  For more information please see our{" "}
                  <a
                    href="https://www.rarerecruitment.co.uk/cookie-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Cookie Policy
                  </a>
                  .
                </P>
              </CookieSection>

              <CookieSection>
                <H3>Strictly necessary cookies</H3>
                <P>
                  These are cookies that are required for the operation of our
                  website. They include, for example, cookies used for
                  authentication, security and to optimize our website to your
                  device settings.
                </P>
              </CookieSection>

              <CookieSection>
                <H3>Analytics cookies</H3>
                <P>
                  These cookies allow us to gather analytics to improve the
                  performance and functionality of our Websites. These analytics
                  can include measurements on the number of users on our
                  Websites, how long they stay on our Websites for, and what
                  parts of our Websites they visit. We use this information in
                  aggregate form to help us to improve the way our Websites
                  work.
                </P>
                <SwitchFieldWrapper>
                  <SwitchField
                    id={"analyticsSwitch"}
                    value={this.state.analyticsCookies}
                    onChange={this.handleAnalyticsSwitchChange}
                    callback={console.log(this)}
                  />
                </SwitchFieldWrapper>
              </CookieSection>

              <CookieSection>
                <Button
                  onClick={this.SaveCookiePreferences}
                  label={"Save preferences and close"}
                />
              </CookieSection>
            </ModalContainer>
          </ModalWrapper>
        </MuiThemeProvider>
      </ThemeProvider>
    ) : null
  }
}

export default CookieBanner

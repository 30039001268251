/**
 * Display an svg icon from an svg sprite file
 * See a list of all available Icon types in assets/svgs
 * Example useage <Icon type="avatar" />
 */

import React from "react"
import PropTypes from "prop-types"
import Icon from "components/Icon"
import styled from "styled-components"
import { DEFAULT_DASH } from "utils/utils"

const Wrapper = styled.div``

const IconWrapper = styled.div`
  width: 22px;
  height: 22px;

  svg {
    stroke: none;
  }
`

export const YesNoIcon = ({ id, variant }) => {
  return (
    <Wrapper>
      {variant === "YES" ? (
        <IconWrapper>
          <Icon id={id} variant={"confirm"} />
        </IconWrapper>
      ) : null}
      {variant === "NO" ? (
        <IconWrapper>
          <Icon id={id} variant={"error"} />
        </IconWrapper>
      ) : null}

      {variant !== "NO" && variant !== "YES" ? (
        <span id={id}>{DEFAULT_DASH}</span>
      ) : null}
    </Wrapper>
  )
}

YesNoIcon.propTypes = {
  variant: PropTypes.string.isRequired,
}

export default YesNoIcon

/**
 * AppLoader
 * Shows a loader
 */

import React from "react"
import { Wrapper } from "./styles"
import CircularProgress from "@material-ui/core/CircularProgress"

export const AppLoader = ({ top, size }) => {
  return (
    <Wrapper top={top}>
      <CircularProgress
        size={size || 50}
        style={{ color: "#39b69b" }}
        thickness={3}
      />
    </Wrapper>
  )
}

export default AppLoader

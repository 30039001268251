/* global __webpack_nonce__ */ // eslint-disable-line no-unused-vars
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "babel-polyfill"
import "svgxuse/svgxuse"
import "utils/classlist-shim"
import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
// import * as serviceWorker from './serviceWorker';
// __webpack_nonce__ = window.NONCE_ID //eslint-disable-line  no-native-reassign

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

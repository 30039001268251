class LocalStorageHelper {
  /**
   * Add a localStorage item
   * @param key
   * @param value
   */
  save = (key, value) => {
    window.localStorage.setItem(key, value)
  }

  /**
   * loads a localStorage item
   * @param key
   * @returns {*}
   */
  load = key => window.localStorage.getItem(key)

  /**
   * removes a localStorage item
   * @param key
   */
  remove = key => {
    window.localStorage.removeItem(key)
  }

  /**
   * removes localStorageStorage
   */
  removeall = () => {
    window.localStorage.clear()
  }
}

export default new LocalStorageHelper()

/**
 * Redux module that handles searching of candidates
 */
import { createAction, handleActions } from "redux-actions"
import { searchCandidates as apiSearchCandidates } from "services/search"
import {
  emitProcess,
  createSearchResource,
  replaceIndex,
  isLiveRoles,
} from "utils/utils"
import { getSubdomainFromUrl } from "utils/productUtils"
import { getFormConfigurationFromOrganization } from "utils/configUtils"

// ------------------------------------
// Constants
// ------------------------------------

export const SEARCH_SET_FILTERS = "SEARCH_SET_FILTERS"
export const SEARCH_SET_ROLE_FILTERS = "SEARCH_SET_ROLE_FILTERS"
export const SEARCH_SET_EVENT_FILTERS = "SEARCH_SET_EVENT_FILTERS"

export const SEARCH_LOAD = "SEARCH_LOAD"
export const SEARCH_RESET = "SEARCH_RESET"
export const SEARCH_SET_SORTING = "SEARCH_SET_SORTING"
export const UPDATE_STAGE = "UPDATE_STAGE"
export const SET_ROLE = "SET_ROLE"
export const SET_STATUS = "SET_STATUS"
export const RESET_PAGINATION = "RESET_PAGINATION"
export const SET_SCREENING_VIEW = "SET_SCREENING_VIEW"
export const LOAD_TOTAL_SUBSUBSTAGE = "LOAD_TOTAL_SUBSUBSTAGE"
export const SAVE_SORT = "SAVE_SORT"

// ------------------------------------
// Actions
// ------------------------------------
const searchSetFilters = createAction(SEARCH_SET_FILTERS)
const searchSetRoleFilters = createAction(SEARCH_SET_ROLE_FILTERS)
const searchSetEventFilters = createAction(SEARCH_SET_EVENT_FILTERS)
const searchLoad = createAction(SEARCH_LOAD)
const searchReset = createAction(SEARCH_RESET)
const loadTotalForSubStage = createAction(LOAD_TOTAL_SUBSUBSTAGE)
const searchSetSorting = createAction(SEARCH_SET_SORTING)
const updateStage = createAction(UPDATE_STAGE)
const setRoleData = createAction(SET_ROLE)
const setStatus = createAction(SET_STATUS)
const setScreeningView = createAction(SET_SCREENING_VIEW)
const resetPagination = createAction(RESET_PAGINATION)
const saveSort = createAction(SAVE_SORT)

// types
export const TYPE_SEARCH_LOAD = "search:load"

/**
 * Search candidates
 * @param resource
 * @param page
 * @returns {Function}
 */

const setRole = (role, history, pathname) => {
  return (dispatch, getState) => {
    dispatch(setRoleData(role))
    if (history) {
      history.push(pathname)
    }
  }
}

const searchCandidates = (
  page,
  history,
  delta,
  saveTotal,
  screening,
  resultsPerPage,
  screenDelta
) => {
  return (dispatch, getState) => {
    const state = getState()
    const {
      candidatesSortBy,
      candidatesSortField,
      candidatesPagination,
      candidatesFilters,
      role,
      stage,
      substage,
    } = state.search
    const data = state.data
    dispatch(searchReset())
    dispatch(emitProcess("loading", TYPE_SEARCH_LOAD))

    const selectedPage = page || page === 0 ? page : candidatesPagination.number

    const options = {
      role,
      stage,
      substage,
      subdomain: isLiveRoles()
        ? role.organization.subdomain
        : getSubdomainFromUrl(),
    }
    const organization = isLiveRoles() ? role.organization : data.organization
    const config = getFormConfigurationFromOrganization(organization)

    // we use the search resource to tell FetchCandidates what fields we want
    // from candidate_response table.
    const viewConfig = JSON.parse(role?.template.viewConfiguration || "{}")
    const candidateResponses = viewConfig.listFields
      ?.filter(field => field.candidateResponse)
      .map(({ id }) => id)

    return apiSearchCandidates(
      organization,
      selectedPage,
      candidatesSortField,
      candidatesSortBy,
      createSearchResource(candidatesFilters, {
        ...options,
        candidateResponses,
      }),
      resultsPerPage
        ? resultsPerPage
        : candidatesPagination.size > 0
        ? candidatesPagination.size
        : 20,
      config?.hr?.dontUseFetchCandidatesLambda
    ).then(
      response => {
        dispatch(emitProcess("success", TYPE_SEARCH_LOAD))
        if (saveTotal) {
          dispatch(loadTotalForSubStage(response.data.totalElements))
        }
        let candidates = response.data

        if (history) {
          if (candidates) {
            dispatch(searchLoad(candidates))
            setTimeout(() => {
              history.push({
                pathname: `/manager/${screening ? "screen" : "candidate"}/${
                  candidates.content[delta].id
                }`,
                search: replaceIndex(window.location, screenDelta),
              })
            }, 400)
          }
        } else {
          return dispatch(searchLoad(candidates))
        }
      },
      error => {
        return dispatch(emitProcess("error", TYPE_SEARCH_LOAD, error))
      }
    )
  }
}

export const actions = {
  searchCandidates,
  searchSetFilters,
  searchSetRoleFilters,
  searchSetEventFilters,
  searchSetSorting,
  updateStage,
  setRole,
  setStatus,
  resetPagination,
  setScreeningView,
  saveSort,
}

// ------------------------------------
// Reducer
// ------------------------------------

const candidatesPagination = {
  first: true,
  hasContent: true,
  hasNext: true,
  hasPrevious: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  size: 0,
  totalElements: 0,
  totalPages: 0,
}

const initialState = {
  candidates: [],
  candidatesFilters: [],
  sorts: {},
  roleFilters: [
    { id: "Active", type: "status", label: "Active", value: "ACTIVE" },
  ],
  eventFilters: [],
  loading: true,
  role: null,
  stage: null,
  substage: null,
  candidatesSortField: "lastChangeAt",
  candidatesSortBy: "DESC",
  candidatesPagination,
}

export default handleActions(
  {
    LOGOUT_SUCCESS: state => {
      return { ...initialState }
    },

    SEARCH_SET_FILTERS: (state, { payload }) => {
      return {
        ...state,
        candidatesFilters: payload,
      }
    },
    SEARCH_SET_ROLE_FILTERS: (state, { payload }) => {
      return {
        ...state,
        roleFilters: payload,
      }
    },
    SEARCH_SET_EVENT_FILTERS: (state, { payload }) => {
      return {
        ...state,
        eventFilters: payload,
      }
    },
    UPDATE_STAGE: (state, { payload }) => {
      if (payload.stage === null && payload.substage == null) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        ...payload,
      }
    },
    SET_ROLE: (state, { payload }) => {
      return {
        ...state,
        role: payload,
      }
    },
    SET_STATUS: (state, { payload }) => {
      let { candidatesFilters } = state
      return {
        ...state,
        candidatesFilters,
      }
    },
    SET_SCREENING_VIEW: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      }
    },
    SEARCH_SET_SORTING: (state, { payload }) => {
      return {
        ...state,
        candidatesSortField: payload.sortField,
        candidatesSortBy: payload.sortBy,
      }
    },
    LOAD_TOTAL_SUBSUBSTAGE: (state, { payload }) => {
      return {
        ...state,
        totalForStage: payload,
      }
    },
    RESET_PAGINATION: (state, { payload }) => {
      return {
        ...state,
        candidatesPagination: {
          ...candidatesPagination,
        },
      }
    },
    SEARCH_RESET: (state, { payload }) => {
      return {
        ...state,
        loading: true,
        candidates: [],
        candidatesPagination: {},
      }
    },
    SEARCH_LOAD: (state, { payload }) => {
      const pagination = Object.assign({}, payload)
      delete pagination.content
      let content = payload.content
      return {
        ...state,
        loading: false,
        candidates: [].concat(content),
        candidatesPagination: pagination,
      }
    },
    SAVE_SORT: (state, { payload }) => {
      const sorts = state.sorts || {}
      sorts[payload.id] = payload
      return {
        ...state,
        sorts,
      }
    },
  },
  initialState
)

import { get, post, put } from "./io"

const url = "/api/public"

/**
 * Signs up user
 * resource is an object:
 * {
 *   firsName, lastName, email, password
 * }
 * @param resource
 * @returns {AxiosPromise}
 */
export function signUp(data) {
  return post({
    url: `${url}/signup`,
    data,
  })
}

export const getOrganization = domain => {
  return get({
    url: `${url}/organization/${domain}/`,
  })
}

export function getVersion() {
  return get({
    url: `${url}/version`,
  })
}

/**
 * Get user that tries to onboard
 * @param token
 * @returns {AxiosPromise}
 */
export function getUserOnboarding(token) {
  return get({
    url: `${url}/userOnBoarding`,
    params: { token },
  })
}

/**
 * Sign up onboarding user
 * @param resource
 * @returns {AxiosPromise}
 */
export function signUpOnboardingUser(data) {
  return post({
    url: `${url}/signup/userOnBoarding`,
    data,
  })
}

/**
 * Verifies user email after sign up
 * @param token
 * @returns {AxiosPromise}
 */
export function confirm(token, candidateId) {
  return post({
    url: `${url}/confirm`,
    data: { token, candidateId },
  })
}

/**
 * Verify email
 * @param token
 * @returns {AxiosPromise}
 */
export function verifyEmail(token) {
  return post({
    url: `${url}/verifyEmail`,
    data: { token },
  })
}

/**
 * Forgot password
 * Sends a reset password link to the specified email address
 * @param email
 * @returns {AxiosPromise}
 */
export function forgot(email) {
  return post({
    url: `${url}/forgot`,
    data: { email },
  })
}

/**
 * Reset password
 * @param newPassword
 * @param token
 * @returns {AxiosPromise}
 */
export function reset(newPassword, token) {
  return post({
    url: `${url}/reset`,
    data: { newPassword, token },
  })
}

/**
 * Load user details
 * @param token
 * @param token
 * @returns {AxiosPromise}
 */
export function loadUser(token) {
  return get({
    url: `${url}/user`,
    params: { token },
  })
}

export function loadUserP(token) {
  return get({
    url: `${url}/userp`,
    params: { token },
  })
}

export function loadInterview(id) {
  return get({
    url: `${url}/interview/${id}`,
  })
}

export function selectInterview(id, selectedTime) {
  return put({
    url: `${url}/interview/${id}`,
    data: { id, selectedTime },
  })
}

/**
 * Load user details
 * @param token
 * @param token
 * @returns {AxiosPromise}
 */
export function loginUserViaToken(token) {
  return get({
    url: `${url}/usertoken`,
    params: { token },
  })
}

/**
 * Load candidate details
 * @param token
 * @param token
 * @returns {AxiosPromise}
 */
export function loginCandidateViaToken(token, organizationId) {
  return get({
    url: `${url}/organization/${organizationId}/ctoken/${token}`,
    params: { token },
  })
}

/**
 * Generate user Token
 * @param email
 * @returns {AxiosPromise}
 */
export function generateToken(email, vantage) {
  return post({
    url: `${url}/user`,
    params: { email },
  })
}

/**
 * Load user Token
 * @param token
 * @returns {AxiosPromise}
 */
export function loadUrlForToken(id) {
  return post({
    url: `${url}/urlToken`,
    data: { id },
  })
}

/**
 * Load Anonymous user
 * @param organisationId
 * @param roleId
 * @returns {AxiosPromise}
 */
export function loadAnonymousUser(
  organisationId,
  roleId,
  params,
  preview,
  override = false
) {
  return post({
    url: `${url}/apply/${organisationId}/${roleId}/?override=${override}&preview=${preview}`,
    params,
  })
}

/**
 * Login Anonymous user
 * @param profileId
 * @returns {AxiosPromise}
 */
export function loginAnonymousUser(profileId) {
  return post({
    url: `${url}/login/${profileId}/`,
  })
}

/**
 * Validate postcode
 * @param postCode
 * @returns {AxiosPromise}
 */
export function validatePostCode(postCode) {
  return get({
    url: `${url}/postCode/validation`,
    params: { postCode },
  })
}

/**
 * Get the report url
 * @param ticket
 * @returns {AxiosPromise}
 */
export function executePublicRequest(ticket) {
  return get({
    url: `${url}/executePublicRequest`,
    params: { ticket },
  })
}

/**
 * get Roles
 * @param organization
 * @returns {AxiosPromise}
 */
export function listRoles(organization, page, data) {
  return post({
    url: `${url}/${organization.id}/roles`,
    params: {
      page,
    },
    data,
  })
}

/**
 * get All Active Events
 * @param organization
 * @returns {AxiosPromise}
 */
export function listEvents(organization) {
  return get({
    url: `${url}/${organization.id}/events`,
  })
}

/**
 * get All Active Events
 * @param organization
 * @returns {AxiosPromise}
 */
export function listAllNonArchivedEvents(organization) {
  return get({
    url: `${url}/${organization.id}/nonarchivedevents`,
  })
}

export function getRole(organizationId, roleId) {
  return get({
    url: `${url}/${organizationId}/roles/${roleId}`,
  })
}

export function generateGoogleLink(type, interviewId) {
  return get({
    url: `${url}/${type}/${interviewId}/google`,
  })
}

export function getEvent(eventId) {
  return get({
    url: `${url}/event/${eventId}`,
  })
}

export function registerEvent(eventId, data) {
  return post({
    url: `${url}/event/${eventId}`,
    data,
  })
}

export function checkInForPublicEvent(eventId, email) {
  return post({
    url: `${url}/event/${eventId}/qr-check-in`,
    data: {
      email,
    },
  })
}

export const getPublicUniversities = (organization, term) => {
  return get({
    url: `${url}/universities`,
    params: {
      term,
      countryBase: organization?.countryBase || "UK",
    },
  })
}

export const getDepartments = organizationId => {
  return get({
    url: `${url}/organization/${organizationId}/departments`,
  })
}

export const getLocations = organizationId => {
  return get({
    url: `${url}/organization/${organizationId}/locations`,
  })
}

export const getEmploymentType = organizationId => {
  return get({
    url: `${url}/organization/${organizationId}/roleTypes`,
  })
}

export const getRoleLevels = organizationId => {
  return get({
    url: `${url}/organization/${organizationId}/roleLevels`,
  })
}

export const getAccessToken = (organizationId, token) => {
  return get({
    url: `${url}/organization/${organizationId}/token/${token}`,
  })
}

export const screenCandidate = (organizationId, token, candidateId) => {
  return get({
    url: `${url}/organization/${organizationId}/token/${token}/candidate/${candidateId}`,
  })
}

export const requestOtpForToken = (organizationId, token) => {
  return get({
    url: `${url}/organization/${organizationId}/requestOtp/${token}`,
  })
}

export const checkOtpForToken = (organizationId, token, otp) => {
  return get({
    url: `${url}/organization/${organizationId}/checkOtp/${token}/${otp}`,
  })
}

export const submitFeedback = (token, organizationId, id, data) => {
  return post({
    url: `${url}/organization/${organizationId}/token/${token}/candidate/${id}`,
    data,
  })
}

export const submitScores = (token, organizationId, id, score, totalScore) => {
  return post({
    url: `${url}/organization/${organizationId}/token/${token}/candidate/${id}/score`,
    data: {
      score,
      totalScore,
    },
  })
}

export const getBuilderFile = builderFile => {
  return get({
    url: `${url}/builder/?fileName=${builderFile}`,
  })
}

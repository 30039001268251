/**
 * Candidate reducer
 * Handles candidate actions
 */

import { createAction, handleActions } from "redux-actions"
import {
  getCandidate as apiGetCandidate,
  copyFromVantage as apiCopyCandidate,
} from "services/candidate"
import {
  updateNote as apiUpdateNote,
  saveMatch as apiSaveMatch,
  deleteMatch as apiDeleteMatch,
  updateCandidateHR as apiUpdateCandidateContact,
} from "services/organization"
import { emitProcess } from "utils/utils"
import { actions as dataActions } from "modules/data"

// ------------------------------------
// Constants
// ------------------------------------
export const CANDIDATE_LOAD = "CANDIDATE_LOAD"
export const CANDIDATE_DUPLICATING = "CANDIDATE_DUPLICATING"
export const CANDIDATE_UPDATE = "CANDIDATE_UPDATE"
export const CANDIDATE_UPDATE_STEP = "CANDIDATE_UPDATE_STEP"

// ------------------------------------
// Actions
// ------------------------------------
const candidateDuplicaating = createAction(CANDIDATE_DUPLICATING)
const candidateLoad = createAction(CANDIDATE_LOAD)
const updateStep = createAction(CANDIDATE_UPDATE_STEP)
const updateCandidate = createAction(CANDIDATE_UPDATE)

// types
export const TYPE_CANDIDATE_LOAD = "candidate:load"

const differentOrganization = (dispatch, state, data) => {
  if (
    (!state.data.organization && data.organization) ||
    (state.data.organization &&
      state.data.organization.candidateId !== data.organization.candidateId)
  ) {
    dispatch(dataActions.setOrganization({ data: data.organization }))
  } else if (
    data.candidateStatus === "PASSPORT_ACTIVE" ||
    data.candidateStatus === "PASSPORT"
  ) {
    dispatch(dataActions.setOrganization({ data: null }))
  }
}

/**
 * Load user
 * @returns {function(*)}
 */
const getCandidate = candidateId => {
  return (dispatch, getState) => {
    dispatch(emitProcess("loading", TYPE_CANDIDATE_LOAD))

    apiGetCandidate(candidateId).then(
      response => {
        if (response.data) {
          const state = getState()
          differentOrganization(dispatch, state, response.data)
          dispatch(emitProcess("success", TYPE_CANDIDATE_LOAD))

          //loading a new app, or the updated at is >= in db
          if (
            !state.candidate ||
            !state.candidate.id ||
            state.candidate.id !== response.data.id ||
            !state.candidate.updatedAt ||
            state.candidate.updatedAt < response.data.updatedAt
          ) {
            return dispatch(candidateLoad(response.data))
          } else {
            return dispatch(candidateLoad(state.candidate))
          }
        } else {
          window.location.href = "/roles"
          // return dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD));
        }
      },
      error => {
        dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD, error))
      }
    )
  }
}

const copyFromVantage = (candidateId, copy) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(emitProcess("loading", TYPE_CANDIDATE_LOAD))
    dispatch(candidateDuplicaating())
    apiCopyCandidate(candidateId, copy).then(
      response => {
        if (response.data) {
          differentOrganization(dispatch, state, response.data)
          dispatch(emitProcess("success", TYPE_CANDIDATE_LOAD))

          return dispatch(candidateLoad(response.data))
        } else {
          window.location.href = "/roles"
          // return dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD));
        }
      },
      error => {
        dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD, error))
      }
    )
  }
}

const connectVantageAccount = candidateId => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(emitProcess("loading", TYPE_CANDIDATE_LOAD))

    apiGetCandidate(candidateId).then(
      response => {
        if (response.data) {
          differentOrganization(dispatch, state, response.data)
          dispatch(emitProcess("success", TYPE_CANDIDATE_LOAD))

          return dispatch(candidateLoad(response.data))
        } else {
          window.location.href = "/roles"
          // return dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD));
        }
      },
      error => {
        dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD, error))
      }
    )
  }
}

const updateNote = (organization, candidateId, id, comment) => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_CANDIDATE_LOAD))

    return apiUpdateNote(organization, candidateId, id, comment).then(
      response => {
        dispatch(emitProcess("success", TYPE_CANDIDATE_LOAD))
        return dispatch(candidateLoad(response.data))
      },
      error => {
        dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD, error))
      }
    )
  }
}

const saveMatch = (organization, candidateId, match) => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_CANDIDATE_LOAD))

    return apiSaveMatch(organization, candidateId, { match }).then(
      response => {
        dispatch(emitProcess("success", TYPE_CANDIDATE_LOAD))
        return dispatch(candidateLoad(response.data))
      },
      error => {
        dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD, error))
      }
    )
  }
}

const deleteMatch = (organization, candidateId, match) => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_CANDIDATE_LOAD))

    return apiDeleteMatch(organization, candidateId, { match }).then(
      response => {
        dispatch(emitProcess("success", TYPE_CANDIDATE_LOAD))
        return dispatch(candidateLoad(response.data))
      },
      error => {
        dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD, error))
      }
    )
  }
}

const updateCandidateHR = (organization, candidateId, data) => {
  return dispatch => {
    dispatch(emitProcess("loading", TYPE_CANDIDATE_LOAD))

    return apiUpdateCandidateContact(organization, candidateId, data).then(
      response => {
        dispatch(emitProcess("success", TYPE_CANDIDATE_LOAD))
        return dispatch(candidateLoad(response.data))
      },
      error => {
        dispatch(emitProcess("error", TYPE_CANDIDATE_LOAD, error))
        return {
          error,
        }
      }
    )
  }
}

const resetCandidate = () => {
  return dispatch => {
    return dispatch(
      candidateLoad({
        step: 0,
      })
    )
  }
}

export const actions = {
  getCandidate,
  copyFromVantage,
  updateCandidateHR,
  updateNote,
  saveMatch,
  deleteMatch,
  connectVantageAccount,
  updateStep,
  updateCandidate,
  resetCandidate,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  id: null,
  timestamp: null,
}

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return { ...initialState }
    },
    CANDIDATE_LOAD: (state, { payload }) => {
      return {
        ...state,
        duplicating: false,
        ...payload,
        timestamp: new Date().getTime(),
      }
    },
    CANDIDATE_DUPLICATING: (state, { payload }) => {
      return { ...state, duplicating: true }
    },
    CANDIDATE_UPDATE: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        duplicating: false,
        timestamp: new Date().getTime(),
      }
    },
    CANDIDATE_UPDATE_STEP: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        duplicating: false,
        timestamp: new Date().getTime(),
      }
    },
  },
  initialState
)

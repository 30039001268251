/**
 * Process Component
 * Shows an alert when a process has failed or a success info message needs to be displayed.
 * Works in collaboration with the redux process module and is usually placed at the entry point of the main app
 * Every async process has a type. If you want to show a message on success or loading then add the corresponding
 * process type to the MESSAGES constant
 */

import React from "react"
import PropTypes from "prop-types"
import Alert from "./Alert/index"
import { MESSAGES } from "utils/constants"

function renderAlert(process) {
  switch (process.status) {
    case "done":
      return MESSAGES[process.type] && MESSAGES[process.type].done ? (
        <Alert text={MESSAGES[process.type].done} type={"success"} />
      ) : null
    case "error":
      return <Alert text={process.message} type={"error"} />
    case "loading":
      return MESSAGES[process.type] && MESSAGES[process.type].loading ? (
        <Alert text={MESSAGES[process.type].loading} type={"info"} />
      ) : null
    default:
      return null
  }
}

export const Process = ({ process }) => {
  return renderAlert(process)
}

Process.defaultProps = {
  timeout: 5000,
  type: "info",
}

Process.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  timeout: PropTypes.number,
  headerComponent: PropTypes.element,
  bodyComponent: PropTypes.element,
  renderFooter: PropTypes.func,
}

export default Process

import styled from "styled-components"
import theme from "styles/theme-main"
import ButtonBase from "@material-ui/core/ButtonBase"
import { media } from "utils/utils"

export const StyledButton = styled(ButtonBase)`
  && {
    margin: ${props =>
      props.noMargin || (props.attrs && props.attrs.noMargin)
        ? 0
        : "10px 10px 10px 0"};
    height: ${props =>
      props.verySmall
        ? theme.buttons.heightVerySmall
        : props =>
            props.small
              ? theme.buttons.heightSmall
              : props.medium
              ? theme.buttons.medium
              : theme.buttons.height};
    width: ${props => props.width || "auto"};
    max-width: 100%;
    min-width: ${props =>
      props.small || props.verySmall || props.width ? "102px" : "216px"};
    border-radius: ${theme.buttons.borderRadius};
    border: ${props => theme.buttons[props.name].border};
    background: ${props =>
      props.disabled
        ? props.name === "crop" && props.disabled
          ? theme.colors.border3
          : theme.buttons.disabled.background
        : theme.buttons[props.name].background};
    color: ${props => theme.buttons[props.name].color};
    font-size: ${props =>
      props.verySmall ? "12px" : props.name === "gridButton" ? "14px" : "16px"};
    letter-spacing: ${props => (props.verySmall ? "2px" : "3px")};
    text-transform: uppercase;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: ${props => (props.small ? "0 18px 0 18px;" : "0 21px 0 24px;")};

    :hover {
      color: ${props => theme.buttons.hover[props.name].color};
      background: ${props => theme.buttons.hover[props.name].background};
    }

    ${media.sm`
      height: ${props => (props.small ? "40px" : "48px")};
      font-size: ${props => (props.small || props.verySmall ? "12px" : "16px")};
      padding: ${props =>
        props.small || props.verySmall ? "0 20px" : "13px 20px 11px"};
      margin-right:0;
    `};

    :focus-visible {
      outline: 2px solid #015ecc !important;
    }
  }
`

import styled from "styled-components"

export const Wrapper = styled.div`
  position: fixed;
  top: ${props => (props.top ? props.top + "px" : "100px")};
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

import { createStore, applyMiddleware, compose } from "redux"
import { routerMiddleware } from "react-router-redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"
import createHistory from "history/createBrowserHistory"
import rootReducer from "./modules"

export const history = createHistory()

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["process", "data"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.devToolsExtension

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(persistedReducer, initialState, composedEnhancers)

// hot reloading
if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept("./modules", () => {
      store.replaceReducer(rootReducer)
    })
  }
}
export const persistor = persistStore(store)
export default store

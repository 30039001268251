class CookieHelper {
  /**
   * Save a cookie
   * @param name
   * @param value
   * @param days
   */
  save = (name, value, days) => {
    let expires
    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toGMTString()
    } else expires = ""
    document.cookie = name + "=" + value + expires + "; path=/"
  }

  /**
   * loads a cookie by name
   * @param name
   * @returns {*}
   */
  load = name => {
    let nameEQ = name + "="
    let ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  /**
   * removes a cookie by name
   * @param name
   */
  remove = name => {
    this.save(name, "", -1)
  }

  /**
   * removes all cookies
   */
  removeall = () => {
    const cookies = document.cookie.split("; ")
    for (let c = 0; c < cookies.length; c++) {
      const d = window.location.hostname.split(".")
      while (d.length > 0) {
        const cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path="
        // eslint-disable-next-line
        const p = location.pathname.split("/")
        document.cookie = cookieBase + "/"
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/")
          p.pop()
        }
        d.shift()
      }
    }
  }
}

export default new CookieHelper()

import LocalStorageHelper from "./localStorage"
import CookieHelper from "./cookie"

class UserSessionHelper {
  /**
   * Add a session
   * @param authObj
   */
  save = authObj => {
    LocalStorageHelper.save("auth", JSON.stringify(authObj))
  }

  /**
   * loads a session
   * @returns authObj
   */
  load = () => {
    const cookieAuthInfo = JSON.parse(CookieHelper.load("auth"))
    if (cookieAuthInfo) {
      this.save(cookieAuthInfo)
      CookieHelper.remove("auth")
    }
    return JSON.parse(LocalStorageHelper.load("auth"))
  }

  /**
   * removes a session
   */
  remove = () => {
    LocalStorageHelper.remove("auth")
    LocalStorageHelper.remove("persist:root")
  }
}

export default new UserSessionHelper()

import styled from "styled-components"

export const RareLogo = styled.div`
  img {
    width: 130px;
  }
`

export const Sorry = styled.div`
  font-size: 49px;
  line-height: 56px;
  margin: 70px 0 60px 0;

  font-family: Europa, "Helvetica", "sans-serif";
  color: #333d42;
`

export const Instruction = styled.div`
  font-size: 20px;
  line-height: 30px;

  font-family: Europa, "Helvetica", "sans-serif";
  color: #333d42;

  a {
    color: #333d42;
    font-size: 16px;
    text-decoration: underline;
    transition: all 0.5s;
  }
`

export const Robot = styled.div`
  margin-left: 150px;
  img {
    width: 422px;
  }
`

export const MainError = styled.div`
  display: flex;
  margin: 20% auto;
  justify-content: center;
`

import styled from "styled-components"
import theme from "styles/theme-main"
import { media } from "utils/utils"
import Text from "components/Text"

export const ControlWrapper = styled.div`
  display: flex;
`

export const SwitchWrapper = styled.div`
  display: flex;
  width: ${props => props.numOfOptions * 108}px;
`

export const Option = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  border: 1px solid
    ${props => (props.hasError ? theme.colors.danger : theme.colors.border)};
  border-right-width: 1px;
  border-left-width: ${props => (props.first ? "1px" : 0)};
  border-bottom-left-radius: ${props => (props.first ? "24px" : 0)};
  border-top-left-radius: ${props => (props.first ? "24px" : 0)};
  border-top-right-radius: ${props => (props.last ? "24px" : 0)};
  border-bottom-right-radius: ${props => (props.last ? "24px" : 0)};
  background-color: ${props =>
    props.selected ? theme.colors.info + " !important" : "white"};
  color: ${props =>
    props.selected ? "white" : theme.colors.textSecondaryDark};
  letter-spacing: 3px;
  font-size: 12px;
  text-transform: uppercase;
  transition: color, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.white} !important;
    background-color: ${theme.colors.border};
  }
`

export const Label = styled(Text)`
  display: ${props => (props.inlineLabels ? "inline-block" : "block")}
  margin-bottom: 14px;
`

export const Hint = styled(Text)`
  display: ${props => (props.inlineLabels ? "inline-block" : "block")}
  margin-left: ${props => (props.inlineLabels ? "10px" : 0)}
  margin-bottom: 14px;
  
  ${media.md`
    margin-left: 0;
    display: block;
  `}
`

export const OptionalWrapper = styled.div`
  display: inline-block;
`

export const ErrorMessage = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 9px;
`

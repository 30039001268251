import styled from "styled-components"
import { fontSize, media, mobileFontSize } from "utils/utils"
import Text from "../components/Text"
import theme from "styles/theme-main"

export const Wrapper = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  background: white;
  ${media.md`
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0 10px;
  `};
`

export const WrapperDesktop = styled.div`
  display: block;
  border-radius: ${props => props.theme.borderRadius};
  background: white;

  ${media.md`
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0 10px;
  `};

  @media (max-width: 800px) {
    display: none;
  }
`

export const WrapperMobile = styled.div`
  display: block;
  border-radius: ${props => props.theme.borderRadius};
  background: white;

  ${media.md`
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0 10px;
  `};

  @media (min-width: 800px) {
    display: none;
  }
`

export const ContentWrapper = styled.div`
  ${media.md`
    padding: 0 17px;
  `};
`

export const Wrapper140 = styled.div`
  padding: 0 ${props => (props.noMargin ? "0" : "140px")};
  margin-top: ${props => (props.largeMarginTop ? "40px" : "5px")};
`

export const SubHeader = styled.div`
  ${props => props.fixed && "position: fixed;"}
  width: 100%;
  margin-top: -30px;
  height: 70px;
  background-color: ${props => props.theme.colors.subheader};

  ${props => props.fixed && "position: fixed; width: 100%; z-index: 9;"}
`

export const FiltersSubHeader = styled.div`
  margin-top: 0;
  height: 90px;
  background-color: ${props => (props.bgcolor ? props.bgcolor : "#f6f6f6")};
`

export const SubHeaderLight = styled.div`
  margin-top: -30px;
  height: 70px;
  background-color: white;

  ${props => props.fixed && "position: fixed; width: 100%; z-index: 9;"}
`

export const SubHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px 18px;
  height: 70px;
`

export const FiltersSubHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: ${props => (props.noPadding ? 0 : "0 30px;")};
`

export const SubHeaderResults = styled.div`
  width: ${props => props.width || "150px"};
  color: white;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 3px;
  font-weight: bold;
`

export const SubHeaderTitle = styled.div`
  ${props => props.isLink && "cursor: pointer;"}
  color: white;
  font-size: 18px;
  letter-spacing: 5px;
  text-transform: uppercase;
`

export const SubHeaderTitleLink = styled.div`
  color: white;
  font-size: 18px;
  letter-spacing: 5px;
  text-transform: uppercase;
  :hover {
    cursor: pointer;
  }
`

export const SubHeaderLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SubHeaderButtons = styled.div`
  display: flex;
  min-width: ${props => props.width || "150px"};
  justify-content: ${props => props.align || "flex-end"};
  align-items: center;
  margin: ${props => (props.margin ? props.margin : "0")};
`

export const SubHeaderButton = styled.div`
  width: ${props => props.size || "40"}px;
  height: ${props => props.size || "40"}px;
  margin-left: ${props => (props.first ? 0 : "10px;")};
  color: ${props =>
    props.disabled ? props.theme.colors.border2 + "!important" : "white"};
  cursor: ${props => (props.disabled ? "inherit" : "pointer")};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  transition: all 0.3s ease-out;
  border-radius: 50%;
  opacity: 1;

  &:hover {
    opacity: 0.5;
  }

  :focus {
    outline: 2px solid #015ecc !important;
  }
`

export const SubHeaderButtonLight = styled.div`
  position: relative;
  width: ${props => props.size || "40"}px;
  height: ${props => props.size || "40"}px;
  margin-left: ${props =>
    props.marginLeft ? props.marginLeft + "px;" : props.first ? 0 : "20px;"};
  color: ${props =>
    props.disabled
      ? props.theme.colors.disabled + "!important"
      : props.theme.colors.textSecondary};
  cursor: ${props => (props.disabled ? "inherit" : "pointer")};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  transition: all 0.3s ease-out;
  border-radius: 50%;
  opacity: 1;

  &:hover {
    color: ${props =>
      props.disabled
        ? props.theme.colors.border2 + "!important"
        : "rgba(74, 91, 104, 0.3)"};
  }
`

export const SubHeaderBackButton = styled.div`
  width: ${props => props.size || "40"}px;
  height: ${props => props.size || "40"}px;
  margin-left: ${props => (props.first ? 0 : "10px;")};
  color: ${props =>
    props.disabled ? props.theme.colors.border2 + "!important" : "white"};
  cursor: ${props => (props.disabled ? "inherit" : "pointer")};
  transition: all 0.3s ease-out;

  &:hover {
    color: ${props => props.theme.colors.inactive};
  }

  :focus {
    outline: 2px solid #015ecc !important;
  }
`

export const SettingsSubHeaderWrapper = styled.div`
  margin-top: 5px;
  height: 100px;
  background-color: #ffffff;
  position: relative;
  z-index: 9;
  border-bottom: 1px solid ${theme.colors.border};
`

export const SettingsSubHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${props => (props.margin ? `${props.margin}` : "110px")};
  height: 100px;
`

export const SettingsSubHeaderTitle = styled.div`
  color: ${props => props.theme.colors.textSecondaryDark};
  letter-spacing: 5px;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
`

export const ActionButton = styled.div`
  display: inline-block;

  width: ${props => props.size || "40"}px;
  height: ${props => props.size || "40"}px;
  margin-left: ${props => (props.first ? 0 : "10px;")};
  color: ${props => props.theme.colors.inactive};
  cursor: pointer;
  border-radius: 50%;
  
  .state_active {
    display: block;
  }
  .state_hover {
    display: none;
  }

  :hover {
    cursor: pointer;
    .state_active {
      display: none;
    }
    .state_hover {
      display: block;
    }
  }

  :focus {
    outline: 2px solid #015ECC !important;
  }

  /*:hover {
    color: ${props => props.theme.colors.textSecondaryDark};
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }*/
`

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SectionText = styled.div`
  ${fontSize("text")};
  font-weight: 400;
  padding-bottom: 30px;

  a {
    display: inline-lock;
    text-decoration: none;
    padding-bottom: 1px;
    color: ${props => props.theme.colors.textPrimary};
    border-bottom: 1px dotted ${props => props.theme.colors.textPrimary};
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  a:hover {
    color: ${props => props.theme.colors.textSecondary};
    border-bottom: 1px dotted ${props => props.theme.colors.textSecondary};
  }

  ${media.md`
    ${mobileFontSize("text", "md")};
    padding: 0 17px 20px; 
  `};
`

export const SectionTitle = styled.h2`
  ${fontSize("title")};
  font-weight: 700;
  margin: 6px 0 16px 0;

  ${media.md`
      ${mobileFontSize("title", "md")};
       padding: 0 17px; 
  `};
`

export const Fieldset = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
`

export const FormHeader = styled.h3`
  ${fontSize("title")};
  line-height: 28px;
  color: ${props => props.theme.colors.textPrimary};
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-top: ${props => (props.extraPadding ? "33px" : 0)};
  margin: 0;

  ${media.md`
    padding-top: ${props =>
      props.extraPadding ? "10px !important" : "0  !important"};
    padding-bottom: 10px;
  `};
`

export const FormLabelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;

  ${media.md`
    display: none;
   `};
`
export const FormHint = styled.span`
  display: inline-block;
  margin-left: 10px;
  color: ${theme.colors.textSecondary};
  font-size: 14px;
  font-weight: 400;
  margin-top: 2px;
`

export const FormLabelGrade = styled.div`
  padding-left: ${props => props.pl || 0};
  display: inline-block;
  width: ${props => props.width || "260px"};
  max-width: ${props => props.maxWidth || "auto"};
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.textSecondary};

  ${media.md`
    color:rgb (125 139 150),
    padding-left:0;
    width: 100%;
    max-width:100%;
    margin-bottom: 6px !important;
  `};
`

export const FormText = styled(Text)`
  letter-spacing: 0.2px;
`

export const FormBody = styled.div`
  padding: ${props =>
    props.variant === "form"
      ? "16px"
      : props.variant === "tabs"
      ? "16px 16px 0 16px"
      : "19px 0"};
  border: ${props =>
    props.variant === "form"
      ? "1px dotted " + props.theme.colors.border
      : "inherit"}
  background-color: ${props =>
    props.variant === "form" ? props.theme.colors.formBG : "inherit"}
    
    ${media.md`
      padding: ${props =>
        props.variant === "tabs"
          ? "0"
          : props.variant === "form"
          ? "16px 18px;"
          : "inherit"};
      margin-right: ${props =>
        props.variant === "form" ? "-10px" : "inherit"};
      margin-left: ${props => (props.variant === "form" ? "-10px" : "inherit")};
    `}
    
`

export const FormRow = styled.div`
  display: ${props => (props.alignEnd ? "flex" : "display")};
  justify-content: ${props => (props.alignEnd ? "flex-end" : "center")};
  padding: ${props =>
    props.last
      ? 0
      : props.smallPadding
      ? "0 0 13px 0"
      : props.verySmallPadding
      ? "0 0 5px 0"
      : "0 0 24px 0"};
  padding-top: ${props => (props.extraPadding ? "30px" : 0)};
`

export const FormRowFlex = styled.div`
  display: flex;
  justify-content: ${props => (props.alignEnd ? "flex-end" : "center")};
  padding: ${props =>
    props.last
      ? 0
      : props.smallPadding
      ? "0 0 13px 0"
      : props.verySmallPadding
      ? "0 0 5px 0"
      : "0 0 24px 0"};
  padding-top: ${props => (props.extraPadding ? "30px" : 0)};
`

export const FormRowReadOnly = styled.div`
  padding-bottom: 5px;
  margin-left: -16px;

  ${media.md`
   margin-left: 0;
  `};
`

export const MobileIBRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 5px 0;
  border-top: 1px dotted ${props => props.theme.colors.border};
`

export const FormRowInline = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-end;
  padding: ${props => (props.last ? 0 : "0 0 24px 0")};

  ${media.md`
    flex-direction: column;
  `}
`

export const FormFooter = styled.div`
  padding: ${props => (props.extraPadding ? "20px 0 40px 0" : "0 0 40px 0")};

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

export const FormFooterText = styled(Text)`
  display: block;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.textSecondary};
`

export const AuthWrapper = styled.div`
  ${media.md`
    padding: 0 8px 0 7px; 
   `};
`

export const AuthFormWrapper = styled.div`
  padding: 38px 0;
`

export const AuthHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
`

export const AuthRow = styled.div`
  padding: ${props => (props.last ? 0 : "0 0 40px 0")};
`

export const AuthFooter = styled.div`
  ${media.md`
    text-align: center;
    
    button:first-child {
      margin:0;
    }
    
    button:last-child {
      margin-top:40px;
    }
  `};
`

export const MobileTab = styled(Text)`
  display: block;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.textSecondaryDark};
  background-color: ${props => props.theme.colors.tabSelectedBG};
  border-bottom: 4px solid ${props => props.theme.colors.primary};
  text-align: center;
  font-size: 12px;
  letter-spacing: 2.6px;
  line-height: 24px;
  padding: 14px 0 12px;
  font-weight: bold;
  text-transform: uppercase;
`

export const MobileTabContainer = styled(Text)`
  display: block;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.textSecondary};
`

export const Metrics = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: ${props => props.margin || "18px 0 14px 0"};
`

export const Metric = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 18px;
`

export const MetricIcon = styled.div`
  width: 17px;
  height: 17px;
  color: ${props => props.color || props.theme.colors.inactive};
  margin-right: 12px;
`

export const MetricLabel = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const MessageContainer = styled.div`
  padding: 10px 0;
  background-color: ${props => (props.success ? "#eef7e0" : "#ffe3e6")};
  /*margin-top: ${props =>
    props.variant && props.variant === "profile" ? "60px" : 0};*/
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1;
`

export const MessageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${media.md`
    padding: 0 10px;
  `};
`

export const MessageIcon = styled.div`
  margin-right: 10px;
  width: 17px;
  height: 17px;
  color: ${props =>
    props.success ? props.theme.colors.success : props.theme.colors.danger};
`

export const SideLabel = styled(Text)`
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  color: #7d8b96;
  font-size: 14px;
  line-height: 20px;
  margin-top: -3px;
  margin-left: -4px;
`

export const Link = styled.div`
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  color: #7d8b96;
  padding-bottom: 2px;
  border-bottom: 1px dotted #7d8b96;
  width: fit-content;

  &:hover {
    color: #323c44;
    border-bottom: 1px dotted #323c44;
  }
`

export const ImportedText = styled.div`
  white-space: pre-line;
`

export const Spacer = styled.div`
  width: 60px;
`

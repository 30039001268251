/**
 * OrganizationService
 * Handles fetching data related to orgs
 * */
import { sget, spost, sdelete, sput } from "./io"

const url = "/organization"

/**
 * Load orgs
 * @param searchTerm
 * @param status
 * @param page
 * @returns {AxiosPromise}
 */
export const listOrganizations = (
  searchTerm,
  status = "ACTIVE", // can be "ACTIVE" or "ARCHIVED"
  page = 0,
  resultsPerPage
) => {
  return sget({
    url: `${url}`,
    params: { status, searchTerm, page, resultsPerPage },
  })
}

export const getAllOrganizations = () => {
  return sget({
    url: `${url}/all`,
  })
}

/**
 * Returns organization of given ID
 * @param id
 * @returns {AxiosPromise}
 */
export const loadOrganization = id => {
  return sget({
    url: `${url}/${id}`,
  })
}

export const getAdminReport = type => {
  return sget({
    url: `/admin/report/${type}`,
  })
}

/**
 * Create org
 * @param resource
 * @returns {AxiosPromise}
 */
export const createOrganization = data => {
  return spost({
    url: `${url}`,
    data,
  })
}

/**
 * Add organization user
 * @param orgId
 * @param resource
 * @returns {AxiosPromise}
 */
export const addOrganizationUser = (orgId, data) => {
  return spost({
    url: `${url}/${orgId}/user`,
    data,
  })
}

export const updateOrganizationUser = (orgId, data) => {
  return spost({
    url: `${url}/${orgId}/user/${data.id}`,
    data,
  })
}

/**
 * Update org
 * @param resource
 * @returns {AxiosPromise}
 */
export const updateOrganization = data => {
  return sput({
    url: `${url}/${data.id}`,
    data,
  })
}

/**
 * Save note
 * @param orgId
 * @param userId
 * @returns {AxiosPromise}
 */
export const updateNote = (organization, id, commentId, comment) => {
  return sput({
    url: `${url}/${organization.id}/candidate/${id}/note/${commentId}`,
    data: {
      comment,
    },
  })
}

/**
 * Save note
 * @param orgId
 * @param userId
 * @returns {AxiosPromise}
 */
export const saveNote = (organization, id, comment) => {
  return sput({
    url: `${url}/${organization.id}/candidate/${id}/note`,
    data: {
      comment,
    },
  })
}

/**
 * Save note
 * @param orgId
 * @param userId
 * @returns {AxiosPromise}
 */
export const saveComment = (organization, id, comment) => {
  return sput({
    url: `${url}/${organization.id}/candidate/${id}/comment`,
    data: {
      comment,
    },
  })
}

/**
 * Save score
 * @param orgId
 * @param userId
 * @returns {AxiosPromise}
 */
export const saveScore = (organization, id, score, totalScore) => {
  return sput({
    url: `${url}/${organization.id}/candidate/${id}/score`,
    data: {
      score,
      totalScore,
    },
  })
}

/**
 * Save score
 * @param orgId
 * @param userId
 * @returns {AxiosPromise}
 */
export const getScores = (organization, id) => {
  return sget({
    url: `${url}/${organization.id}/candidate/${id}/score`,
  })
}

/**
 * Save match
 * @param orgId
 * @param userId
 * @returns {AxiosPromise}
 */
export const saveMatch = (organization, id, data) => {
  return sput({
    url: `${url}/${organization.id}/candidate/${id}/match`,
    data,
  })
}

/**
 * Save match
 * @param orgId
 * @param userId
 * @returns {AxiosPromise}
 */
export const deleteMatch = (organization, id, data) => {
  return sdelete({
    url: `${url}/${organization.id}/candidate/${id}/match`,
    data,
  })
}

/**
 * Save match
 * @param orgId
 * @param userId
 * @returns {AxiosPromise}
 */
export const updateCandidateHR = (organization, id, data) => {
  return sput({
    url: `${url}/${organization.id}/candidate/${id}/update`,
    data,
  })
}

export const listContacts = organizationId => {
  return sget({
    url: `${url}/${organizationId}/contacts`,
  })
}

export const createContact = (organizationId, data) => {
  return spost({
    url: `${url}/${organizationId}/contacts`,
    data,
  })
}

export const updateContact = (organizationId, data) => {
  return spost({
    url: `${url}/${organizationId}/contacts/${data.id}`,
    data,
  })
}

export const getClientEmails = (organization, search) => {
  return sget({
    url: `${url}/${organization.id}/data/ORGANIZATION_EMAIL`,
    params: {
      search,
    },
  })
}

export const getAgencyEmails = (organization, search) => {
  return sget({
    url: `${url}/${organization.id}/data/ORGANIZATION_AGENCY_EMAIL`,
    params: {
      search,
    },
  })
}

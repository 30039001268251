/**
 * Combine reducers
 */

import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import account from "./account"
import candidate from "./candidate"
import data from "./data"
import user from "./user"
import search from "./search"
import process from "./process"
import nav from "./nav"
import menus from "./menus"

export default combineReducers({
  routing: routerReducer,
  account,
  candidate,
  data,
  user,
  search,
  process,
  nav,
  menus,
})

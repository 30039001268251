import { isATSOrganization, isHRNotDisabled } from "utils/utils"
import { isCandidXMode } from "utils/productUtils"

export const getRareFormConfigurationFromCandidate = candidate => {
  return JSON.parse(
    (candidate && candidate.rareApplicationFormConfiguration) || "{}"
  )
}

export const getFormConfigurationFromCandidate = candidate => {
  return candidate.role
    ? JSON.parse(candidate.role.formConfiguration || "{}")
    : {}
}

export const getFormConfigurationFromRole = role => {
  return role ? JSON.parse(role.formConfiguration || "{}") : {}
}

export const getFormConfigurationFromOrganization = organization => {
  return organization
    ? JSON.parse(
        organization.defaultConfiguration ||
          organization.formConfiguration ||
          "{}"
      )
    : {}
}

export const getScreenConfiguration = (organization, screeningView) => {
  const views = getFormConfigurationFromOrganization(organization).views
  const view =
    screeningView && views
      ? views.find(x => parseInt(x.id) === parseInt(screeningView.id)) || {}
      : {}
  return view ? view.configuration || {} : {}
}

export const getDefaultScreenConfiguration = organization => {
  const views =
    organization && getFormConfigurationFromOrganization(organization).views
  return (views && (views.find(x => x.default) || views[0])) || {}
}

export const showEvents = organization => {
  const oconfiguration = getFormConfigurationFromOrganization(organization)
  return isATSOrganization(organization)
    ? isHRNotDisabled(oconfiguration, "events")
    : isCandidXMode()
}

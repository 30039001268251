// import { actions as accountActions } from ' redux/modules/account'
import AuthService from "services/auth"
import axios from "axios"
import store from "store"
import { actions as accountActions } from "modules/account"
import { actions as processActions } from "modules/process"
const clearAuth = () => {
  AuthService.clearAuthInfo()
  setTimeout(() => {
    store.dispatch(accountActions.logout())
  }, 100)
}

const checkTokenActions = (error, resolve, reject) => {
  let objToReplay = {
    initialRequest: error.config,
    resolve: resolve,
    reject: reject,
  }

  AuthService.pushToQueue(objToReplay)

  if (!AuthService.firstTimeFail) {
    AuthService.firstTimeFail = true
    AuthService.refreshToken()
      .then(res => {
        if (res && res.data) {
          AuthService.setAuthInfo(res.data)
          return AuthService.replayQueuedRequests()
        } else {
        }
      })
      .catch(err => {
        reject(err)
        clearAuth()
      })
  }
}

// Handle API request errors
axios.interceptors.response.use(
  response => {
    AuthService.updateAuth()
    return response
  },
  error => {
    return new Promise((resolve, reject) => {
      if (error && !error.response) {
        clearAuth()
        // Alert.error("Some error happened, please try again later");
      } else if (
        error.response.status === 401 &&
        error.response.data &&
        error.response.data.error_description ===
          "Cannot convert access token to JSON"
      ) {
        clearAuth()
      } else if (
        error.response.status === 401 &&
        AuthService.isSavedTokenExpired()
      ) {
        checkTokenActions(error, resolve, reject)
      } else if (error.response.status === 401) {
        if (AuthService.loggedIn()) {
          //clearAuth();
        } else {
          //clearAuth();
          store.dispatch(
            processActions.processFailure({
              type: "login",
              message: error,
            })
          )

          reject(error)
        }
      } else {
        // Alert.error(error.response.data.message);

        if (error.config.clearCredentialsOnError) {
          clearAuth()
        }

        reject(error)
      }
    })
  }
)

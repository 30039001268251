/**
 * AuthService
 * Handles authentication
 */

import CookieHelper from "../utils/cookie"
import UserSessionHelper from "../utils/userSession"

import { post, sdelete, action } from "./io"
// import moment from "moment";
import { addSeconds, compareAsc } from "date-fns"

const clientId = "web"
const secretKey = "RjVTFsgeEUmqCQsuzkFbP"
const authKey = "Basic " + btoa(clientId + ":" + secretKey)
const deviceId = "555"
const applicationType = "PATIENT"
export const varToEncrypt = "123as#fasdfasdfasdf"

class AuthService {
  loginUrl = "/oauth/token"
  logoutUrl = "/oauth/access-token"

  authObj = {
    isLoggedIn: false,
    access_token: null,
    token_type: null,
    refresh_token: null,
    expires_in: null,
    scope: "",
  }

  roles = []
  firstTimeFail = false
  requestsQueue = []

  defaultHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authKey,
    "X-Client": "WEB",
  }

  textHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authKey,
    "X-Client": "WEB",
  }

  loginHeaders = {
    Accept: "application/json",
    "Content-Type": undefined,
    authorization: authKey,
    "X-Client": "WEB",
  }

  isSavedTokenExpired = () => {
    const authInfo = UserSessionHelper.load()

    let hasExpired = false
    let now = new Date()
    if (
      authInfo &&
      authInfo.expiresOn &&
      compareAsc(now, new Date(authInfo.expiresOn)) === 1
    ) {
      console.log("token has expired. i will refresh it")
      hasExpired = true
    }

    return hasExpired
  }

  loggedIn = () => {
    const authInfo = UserSessionHelper.load()
    let isLoggedIn = false
    if (authInfo) {
      isLoggedIn = authInfo
    }
    return isLoggedIn
  }

  login = (username, password, otp, organization) => {
    let data = new FormData()

    data.append("username", username)
    data.append("password", password)
    data.append("grant_type", "password")
    data.append("partner", organization)
    data.append("otp", otp)
    data.append(
      "otpCookie",
      CookieHelper.load(`otpcookie-${username}`) || undefined
    )
    data.append("scope", "write")
    data.append("client_id", clientId)
    data.append("application_type", applicationType)

    return post({
      url: this.loginUrl,
      data: data,
      headers: this.loginHeaders,
    })
  }

  loginByToken = (token, organization) => {
    let data = new FormData()

    data.append("password", token)
    data.append("grant_type", "password")
    data.append("partner", organization)
    data.append("scope", "write")
    data.append("client_id", clientId)
    data.append("type", "USER_TOKEN")
    data.append("application_type", applicationType)

    return post({
      url: this.loginUrl,
      data: data,
      headers: this.loginHeaders,
    })
  }

  loginOAuth = (token, organization) => {
    let data = new FormData()

    data.append("username", "token")
    data.append("password", token)
    data.append("grant_type", "password")
    data.append("partner", organization)
    data.append("scope", "write")
    data.append("client_id", clientId)
    data.append("application_type", applicationType)

    return post({
      url: this.loginUrl,
      data: data,
      headers: this.loginHeaders,
    })
  }

  refreshToken = () => {
    const authObj = UserSessionHelper.load()
    if (authObj.refresh_token) {
      return post({
        url:
          this.loginUrl +
          "?" +
          "refresh_token=" +
          authObj.refresh_token +
          "&grant_type=refresh_token" +
          "&scope=write" +
          "&client_id=" +
          clientId,
        clearCredentialsOnError: true,
        headers: this.defaultHeaders,
      }).catch(err => {
        console.log("ERROR")
      })
    } else {
      return new Promise((_, reject) => reject())
    }
  }

  logout = () => {
    return sdelete({
      url: this.logoutUrl,
      data: JSON.stringify({
        deviceId: deviceId,
      }),
    })
  }

  setAuthInfo = authObj => {
    authObj.isLoggedIn = true
    authObj.expiresOn = addSeconds(new Date(), authObj.expires_in)
    this.authObj = authObj
    UserSessionHelper.save(authObj)
  }

  clearRefreshToken = timeout => {
    const authInfo = UserSessionHelper.load()
    authInfo.refresh_token = null
    authInfo.expiresOn = addSeconds(new Date(), timeout * 60)
    UserSessionHelper.save(authInfo)
    CookieHelper.save("timeout", timeout)
  }

  updateAuth = () => {
    const authObj = UserSessionHelper.load()
    if (authObj && !authObj.refresh_token) {
      authObj.expiresOn = addSeconds(
        new Date(),
        CookieHelper.load("timeout") * 60
      )
      UserSessionHelper.save(authObj)
    }
  }

  clearAuthInfo = () => {
    this.authObj = {}
    UserSessionHelper.remove()
  }
  getAuthorizationHeaders = () => {
    const authInfo = UserSessionHelper.load()
    const authHeaderValue =
      authInfo && authInfo.access_token ? authInfo.access_token : null
    return Object.assign({}, this.getDefaultHeaders(), {
      Authorization: "Bearer " + authHeaderValue,
    })
  }

  getDefaultHeaders = () => {
    return this.defaultHeaders
  }

  getTextHeaders = () => {
    return this.textHeaders
  }

  pushToQueue = promise => {
    this.requestsQueue.push(promise)
  }

  clearQueue = () => {
    this.requestsQueue = []
  }

  replayQueuedRequests = () => {
    let req = this.requestsQueue
    for (let i = 0; i < req.length; i++) {
      req[i].initialRequest.headers = this.getAuthorizationHeaders()
      // Replay request
      action(req[i].initialRequest, { authRequired: true })
        .then(response => {
          req[i].resolve(response)
        })
        .catch(response => {
          req[i].reject(response)
        })
    }

    this.clearQueue()
    this.firstTimeFail = false
  }
}

export default new AuthService()

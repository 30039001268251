import React, { Component } from "react"
import { OptionLabelContainer, OptionLabel } from "./styles"

export class OptionalLabel extends Component {
  render() {
    return (
      <OptionLabelContainer>
        <OptionLabel>Optional</OptionLabel>
      </OptionLabelContainer>
    )
  }
}

export default OptionalLabel

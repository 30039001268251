/**
 * Redux module that saves navigation related data
 */
import { handleActions } from "redux-actions"

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  currentPath: null,
  previousPath: null,
}

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return { ...initialState }
    },
    "@@router/LOCATION_CHANGE": (state, { payload }) => {
      return {
        ...state,
        currentPath: payload,
        previousPath: state.currentPath,
      }
    },
  },
  initialState
)

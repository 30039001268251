import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { actions as accountActions } from "../../modules/account"
import { actions as userActions } from "../../modules/user"
import { withRouter } from "react-router-dom"

// ToDo: Find a better alternative for passing the location than withRouter

import View from "./"

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = dispatch => {
  const actions = {
    logout: accountActions.logout,
    getUser: userActions.getUser,
    clearUser: userActions.clearUser,
  }

  return bindActionCreators(actions, dispatch)
}

const mapStateToProps = ({ account, user, process, routing, data }) => ({
  logoutLoading: account.loading,
  account: account,
  process: process,
  organization: data.organization,
  location: routing.location,
  user: user ? user : {},
})

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:

    import { createSelector } from 'reselect'
    const counter = (state) => state.counter
    const tripleCount = createSelector(counter, (count) => count * 3)
    const mapStateToProps = (state) => ({
      counter: tripleCount(state)
    })

    Selectors can compute derived data, allowing Redux to store the minimal possible state.
    Selectors are efficient. A selector is not recomputed unless one of its arguments change.
    Selectors are composable. They can be used as input to other selectors.
    https://github.com/reactjs/reselect    */

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))

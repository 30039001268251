import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import View from "./"

const mapStateToProps = ({ account, user, process, routing }) => ({
  location: routing.location,
  user: user ? user : {},
})

export default withRouter(connect(mapStateToProps)(View))
